import firebase from "firebase/app";
const authz = firebase.auth();
export async function offritoreReg(app,auth,email,password,db,nome,cognome,nomeAz,indirizzo,luogo,telefono,selectedOptionAz,emailjs,toSend,history,plan){
      
        await app
          .auth()
          .createUserWithEmailAndPassword(email, password); //registra l' account

          db.collection('UsersId').doc(auth.currentUser.uid).set({
            Email: email,
            CurrentEmail: email
          }).then(()=>{
        db.collection("Users") //inserisce negli utenti
          .doc(email)
          .set({
            Id: auth.currentUser.uid,
            Nome: nome,
            Cognome: cognome,
            Nome_Azienda: nomeAz,
            Indirizzo: indirizzo,
            Comune: luogo,
            Email: email,
            Password: password,
            Numero: telefono,
            Tipo: selectedOptionAz,
            MemoryUsed: 0,
            Sedi_Create: 1, //sedi create
            Plan: plan,
            Max_Sott: plan===0?1:plan===1?5:10,
            Max_Cli: plan===0?5:plan===1?10:1000,
            Current_Sott: 0,
            Current_Cli: 0,
            CurrentEmail: email

          })
          .then((docRef) => {
            db.collection("Users") //aggiunge la sede
              .doc(email)
              .collection("Sedi")
              .doc(luogo)
              .set({
                Indirizzo: indirizzo,
                Comune: luogo,
                Numero: telefono,
                Index: 999,
              })
              
                              .then(() => {
                                db.collection("Offritori") //aggiunge l'off
              .doc(email)
        
              .set({
                Email: email,
                Nome_Azienda: nomeAz,
              })
              
                              .then(() => {
                                authz.currentUser.sendEmailVerification().then(()=>{
                                  alert(
                                    "Registrazione completata! Vi sarà mandata una mail di conferma."
                                  )
                                  history.push("/login"); //rimanda alla pagina di login
                                })
                              })
                            })
                          })
                      
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
        })
      
}