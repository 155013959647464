import React,{useEffect, useState} from 'react'
import NavBar from '../NavBar/NavBar'
import firebase from "firebase";
import { getUtente, prendiCategorie,checkWriteS,checkDeleteS } from '../EngineClienti/engineClienti';
import {  IconButton, Tooltip } from '@mui/material';
import LoopIcon from '@material-ui/icons/Loop';
import Notifiche from '../Notifiche/Notifiche';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

const Categorie = ({history}) => {

    const db = firebase.firestore()
    const auth = firebase.auth()

    const [user,setUser] = useState(null)
    const [sedi,setSedi] = useState([]) //lista sedi
    const [categorie,setCategorie] = useState([]) //lista categorie

    //filtri
    const [sedeF,setSedeF] = useState('default')
    const [findInput,setFindInput] = useState('')

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      useEffect(()=>{
        if(user!==null){
          prendiCategorie(db,user.Email,setSedi,setCategorie)
      }
    },[user])

    function reGet (){
        prendiCategorie(db,user.Email,setSedi,setCategorie)
      
      }

    const Buttons = () =>{

        return(
          <div style={{display:'flex',alignItems:'center',gap:'1.5rem'}}>
            <div>
            <h4 onClick={()=>history.push('/categorie/aggiungicategoria')}  className='text-buttons' >
                    AGGIUNGI CATEGORIA
                  </h4>
            </div>
          
          </div>
        )
      }
      function renderCategorie(sede){
      
        if((sedeF===''||sedeF==='default'||sede.Sede===sedeF)&&(findInput===''||sede.Nome.toLowerCase().includes(findInput.toLowerCase()))){
          return true
        }else{
          return false
        }
      }

         //sorting
   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

   const onSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortedCat = React.useMemo(() => {
    let sortableItems = [...categorie];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (typeof a[sortConfig.key] === 'string') {
          return a[sortConfig.key].localeCompare(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        } else if (sortConfig.key === 'date') {
          return new Date(a[sortConfig.key]) - new Date(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        }else if (sortConfig.key === 'Ele') {
          return (a[sortConfig.key] - b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        }else if (sortConfig.key === 'Doc') {
          return (a[sortConfig.key] - b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        } else {
          return 0;
        }
      });
    }
    return sortableItems;
  }, [categorie, sortConfig]);


  function deleteCat(cat){
    
    if(cat.Ele!==0&&cat.Doc!==0){
      alert('Prima di eliminare la categoria assicurati di eliminare tutti gli elementi e documenti annessi.')
      return
    }else{
      var cE = false
    var cD = false
    var iE = 0
    var iD = 0
      db.collection('Users').doc(user.Email).collection('Sedi').doc(cat.Sede).collection('Categorie').doc(cat.Id).collection('Sottocategorie').get().then((sottocategorie)=>{
        if(sottocategorie.docs.length>0){
          sottocategorie.forEach((sott)=>{
            iE++
            console.log(sott.id)
            db.collection('Users').doc(user.Email).collection('Sedi').doc(cat.Sede).collection('Categorie').doc(cat.Id).collection('Sottocategorie').doc(sott.id).delete().then(()=>{
              if(iE===sottocategorie.docs.length){
                cE=true
              }
            })
            
          })
        }else{
          console.log('bewlla')
          cE=true
        }
      }).then(()=>{
        console.log(cE)
        db.collection('Users').doc(user.Email).collection('Sedi').doc(cat.Sede).collection('Categorie').doc(cat.Id).collection('Cartelle').get().then((cartelle)=>{
          if(cartelle.docs.length>0){
            cartelle.forEach((cart)=>{
              iD++
            db.collection('Users').doc(user.Email).collection('Sedi').doc(cat.Sede).collection('Categorie').doc(cat.Id).collection('Cartelle').doc(cart.id).delete().then(()=>{
              if(iD===cartelle.docs.length){
                cD=true
              }
            })
            
            })
          }else{
            cD=true
          }
          console.log(cD,cE)
          if(cD===true&&cE===true){
            db.collection('Users').doc(user.Email).collection('Sedi').doc(cat.Sede).collection('Categorie').doc(cat.Id).delete().then(()=>{
              reGet()
              alert('Categoria eliminata con successo.')
            })
          }
        })
       
      })
     
    }
  }
      

  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
                <NavBar/>
                {user!==null&&user!==null?<Notifiche user={user}/>:null}

    <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
    <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>CATEGORIE</h4>
       
      </div>
      {user!==null?checkWriteS(user.Permissions)===true?<Buttons/>:null:null}
      <div className='box-shadow' style={{marginTop:'0.5rem',borderRadius:5,width:'55vw',backgroundColor:'white',height:'3rem',display:'flex',alignItems:'center'}}>
          <div style={{width:'7vw',height:'3rem',borderRight:'1px solid lightgrey',}}>
          <h4 className="normal-text" style={{marginLeft:'auto',marginRight:'auto',marginTop:'0.3rem',fontSize:'1rem',width:'fit-content'}}>
           {categorie.length}
          </h4>
          <h4 className="sub-text" style={{marginLeft:'auto',marginRight:'auto',fontSize:'0.8rem',width:'fit-content'}}>
          {'Categorie totali'}
          </h4>
          </div>
          
          <select
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw'}}
                defaultValue={"default"}
                value={sedeF}
                className='input-style'
                onChange={(e) => {
                  setSedeF(e.target.value);
                 
                }}
              >
                <option value={"default"} disabled>
                  Filtra per sede...
                </option>
                <option value={""}>Tutte</option>

                {
                    sedi.length>0?sedi.map((sede,n)=>(
                        <option index={n}  value={sede.Id}>{sede.Comune}</option>

                    )):null
                }


              </select>
             {/* */}
      
              <div  style={{marginLeft:'auto'}}>
                <input onChange={(e)=>
                  setFindInput(e.target.value.toLowerCase())}
                  className="input-style2" style={{width:'30rem',height:'1rem',fontWeight:'normal',fontFamily:'Poppins'}} type='text' placeholder={"Cerca categoria per: nome..."}></input>
                </div>            
          <div style={{marginLeft:10,marginRight:5}}>
          <Tooltip style={{width:'fit-content'}} title="Aggiorna lista">
                 <IconButton onClick={()=>reGet()} style={{backgroundColor:'#192bc2',width:'fit-content',height:'fit-content'}}  
                // onClick={()=>getEle(sedi,email)}
                 >
                 <LoopIcon style={{verticalAlign: 'middle', color: 'white',fontSize:'1rem'}}/>
                 </IconButton>
                 </Tooltip>
          </div>
          </div>
          <div style={{marginTop:10,borderRadius:5,width:'55vw',backgroundColor:'#14213d',height:'3rem',marginRight:'auto',display:'flex',alignItems:'center',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
          
          <div style={{borderRight:'1px solid lightgrey',width:'15vw',height:'3rem',alignItems:'center',justifyContent:'center',}} onClick={() => onSort('Nome')}>
            <h4 className="header-table" >Nome {sortConfig.key === 'Nome' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}} onClick={() => onSort('Sede')}>
          <h4 className="header-table" >Sede {sortConfig.key === 'Sede' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}} onClick={() => onSort('Doc')}>
          <h4 className="header-table" >Documenti {sortConfig.key === 'Doc' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}} onClick={() => onSort('Ele')}>
          <h4 className="header-table" >Elementi {sortConfig.key === 'Ele' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}} onClick={() => onSort('Ele')}>
          <h4 className="header-table" >Azioni</h4>

          </div>
          </div>
          <div style={{overflowY:'auto',height:'74vh'}}>
          {sortedCat.length>0?sortedCat.map((ele,n)=>(
           renderCategorie(ele)===true?
           <div key={n} className='table-row' style={{width:'55vw'}}>
            <div  style={{borderRight:'1px solid lightgrey',width:'15vw',height:'6rem',display:'flex',alignItems:'center'}}>
              <div>
              <Tooltip title={ele.Nome}>

<h4 className="medium-text" style={{whiteSpace: 'nowrap',color:ele.Nome==='Antincendio'?'#FF0060':ele.Nome==='Elettrico'?'#F7D060':'green',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'9vw'}}>
                {ele.Nome}
              </h4>
              </Tooltip>
                </div>
              
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <div style={{display:'flex',alignItems:'center'}}>
              <Tooltip title={ele.SedeNome}>
              <h4 className="medium-text" style={{maxWidth:'7.3vw'}} >
              {ele.SedeName?ele.SedeName!==''?ele.SedeName:ele.SedeNome!==''?ele.SedeNome:'Non inserito':ele.SedeNome!==''?ele.SedeNome:'Non inserito'}
              </h4>
              </Tooltip>
              
              </div> 
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <div style={{display:'flex',alignItems:'center'}}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
              {ele.Doc}
              </h4>
              
              </div>     
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{display:'flex',alignItems:'center'}}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Ele}
              </h4>
            
              </div>
            </div>
            <div style={{width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{marginLeft:'auto',alignItems:'center',flexDirection:'row',width:'fit-content',marginRight:'20px'}}>
            <h4 onClick={()=>history.push(`/categorie/categoria/${ele.Id}`)} style={{borderBottom:'1px solid #192bc2',color:'#192bc2',cursor:'pointer',fontSize:'0.8rem',}} >
                Dettagli
              </h4>
             {checkDeleteS(user!==null?user.Permissions:null)===true? <div style={{width:'fit-content',marginLeft:'auto'}}>
              <IconButton onClick={()=>deleteCat(ele)} style={{width:'fit-content'}} >
              <DeleteIcon style={{color:'#FF0060'}}/>
                </IconButton>
                </div>:null}
            
              </div>
            </div>
           
  
            </div>:null
           
          )): <h4 className='normal-text' style={{marginTop:'1rem'}}>Nessuna sede inserita.</h4>}
        </div>
        </div>
    
    </div>
  )
}

export default Categorie