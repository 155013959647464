//classe per la generazione dei pdf interventi e man
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import mysicLungo from "./images/mysic-lungo.png";
const GenerazionePdf=()=>{
    const generaPdfManutenzione=async (manutenzione,operatore,elementi,sottoscadenze,setLoading)=>{
        console.log(sottoscadenze)
        const pdf = new jsPDF('p','mm','a4',true);
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        const esito = manutenzione.Esito? manutenzione.Esito: 'Non definito'
        var mainPagina = '<html><head><style>.custom {border:0.01px solid black;border-collapse:collapse;}</style></head><body>'+
        '<div style="width:'+pdf.internal.pageSize.getWidth()+'mm;height:29.7cm;">'+
        '<div style="justify-content: space-between;display:flex;flex-direction:row;height:2cm;width:90%;margin-left:auto;margin-right:auto;">'+ 
        '<div style="height:100%;margin-left:0;padding-top:4mm">'+
                '<label style="font-size:8mm;font-weight:bold;color:#192bc2">RIEPILOGO SCADENZA</label>'+
                '</br>'+
                '<label style="font-size:4mm;color:grey">'+manutenzione.Titolo+'</label>'+
            '</div>'+
            '<img style=width:30%;height:100%;align-self:flex-end;margin-right:0;margin-top:0;margin-bottom:auto" src="'+mysicLungo+'"/>'+
        '</div>'+
        '<div style="width:90%  ;border:1px solid lightgrey;margin-left:auto;margin-right:auto;margin-bottom:20px;margin-top:20px"></div>'+
        '<table class="custom" style="table-layout:fixed;width:90%;height:20%;margin-left:auto;margin-right:auto">'+
            '<colgroup>'+
                '<col span="1" style="width:20%">'+
                '<col span="1" style="width:80%">'+
            '</colgroup>'+
            '<tbody>'+
                '<tr>'+
                    '<th class="custom" colspan="2"><label style="color:#14213d;font-size:4mm;font-weight:bold">Anagrafica cliente</label></th>'+
                '</tr>'+
                '<tr>'+
                   '<th class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:grey">Ragione sociale</label></th>'+
                    '<td class="custom" style="text-align:left;" ><label style="margin-left:5px;font-size:3.2mm;color:black">'+manutenzione.Nome_AziendaCli+'</label></td>'+
                '</tr>'+
                '<tr>'+
                    '<th class="custom"  style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Nome e Cognome</label></th>'+
                    '<td class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:black">'+manutenzione.Nome+' '+manutenzione.Cognome+'</label></td>'+
                '</tr>'+
                '<tr>'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Sede di intervento</label></th>'+
                    '<td class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:black">'+manutenzione.Sede_cli+' '+ manutenzione.Indirizzo+'</label></td>'+
                '</tr>'+
                '<tr>'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Email e telefono</label></th>'+
                    '<td class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:black">'+manutenzione.Cliente+' '+manutenzione.Numero_telefono_cli+'</label></td>'+
                '</tr>'+
            '</tbody>'+
        '</table>'+
        '<div style="width:90%;border:1px solid lightgrey;margin-left:auto;margin-right:auto;margin-bottom:20px;margin-top:20px"></div>'+
            '<table class="custom" style="table-layout:fixed;width:90%;height:20%;margin-left:auto;margin-right:auto">'+
            '<colgroup>'+
                '<col span="1" style="width:20%">'+
                '<col span="1" style="width:80%">'+
            '</colgroup>'+
            '<tbody>'+
                '<tr>'+
                    '<th colspan="2" class="custom"><label style="color:#14213d;font-size:4mm;font-weight:bold">Anagrafica Operatore</label></th>'+
                '</tr>'+
                '<tr style="">'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Ragione sociale</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+operatore.Nome_Azienda+'</label></td>'+
                '</tr>'+
                '<tr style="">'+
                    '<th class="custom" style="text-align:left;k"><label style="margin-left:5px;font-size:3.2mm;color:grey">Indirizzo Sede</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+operatore.Indirizzo+' '+operatore.Sede+'</label></td>'+
                '</tr>'+
                '<tr style="k">'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Email e telefono</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+manutenzione.Operatore+' '+operatore.Telefono+'</label></td>'+
                '</tr>'+
            '</tbody>'+
        '</table>'+
               '<div style="width:90%;border:1px solid lightgrey;margin-left:auto;margin-right:auto;margin-bottom:20px;margin-top:20px"></div>'+
            '<table class="custom" style="table-layout:fixed;width:90%;height:20%; margin-left:auto;margin-right:auto">'+
            '<colgroup>'+
                '<col span="1" style="width:20%">'+
                '<col span="1" style="width:80%">'+
            '</colgroup>'+
            '<tbody>'+
                '<tr>'+
                    '<th class="custom" colspan="2"><label style="color:#14213d;font-size:4mm;font-weight:bold">Parametri Intervento</label></th>'+
                '</tr>'+
                '<tr style="">'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Data</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+manutenzione.Data+'</label></td>'+
                '</tr>'+
               '<tr style="">'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Soggetto</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+manutenzione.Oggetto+'</label></td>'+
                '</tr>'+
                '<tr style="">'+
                   ' <th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Stato'+
                    '</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+manutenzione.Stato+'</label></td>'+
                '</tr>'+
                '<tr style="">'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Descrizione</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+manutenzione.Descrizione+'</label></td>'+
                '</tr>'+
            '</tbody>'+
        '</table>'+
    '</div></body></html>';

        var header = '<div style="width:'+pdf.internal.pageSize.getWidth()+'mm;height:29.7cm;">'+
        '<div style="justify-content: space-between;display:flex;flex-direction:row;height:2cm;width:90%;margin-left:auto;margin-right:auto;">'+ 
        '<div style="height:100%;margin-left:0;padding-top:4mm">'+
                '<label style="font-size:8mm;font-weight:bold;color:#192bc2">RIEPILOGO SCADENZA</label>'+
                '</br>'+
                '<label style="font-size:4mm;color:grey">'+manutenzione.Titolo+'</label>'+
            '</div>'+
            '<img style=width:30%;height:100%;align-self:flex-end;margin-right:0;margin-top:0;margin-bottom:auto" src="'+mysicLungo+'"/>'+
        '</div><div style="width:90%  ;border:1px solid lightgrey;margin-left:auto;margin-right:auto;margin-bottom:20px;margin-top:20px"></div>'+
        '</div>'


        // HTML per "Esito complessivo" con checkbox Positivo/Negativo
var infRep2 = `
<div style="width:${pdf.internal.pageSize.getWidth()}mm; height:29.7cm">
<div style="display: flex; justify-content: space-between; align-items: center;">
    <label style="margin-left:5%; color:#14213d; font-size:8mm; font-weight:bold">Esito complessivo:</label>
    <div style="display: flex; align-items: center;">
        <label style="color:black; font-size:6mm; margin-right: 10mm;">Positivo</label>
        <input type="checkbox" style="width: 6mm; height: 6mm;" ${esito === "Positivo" ? "checked" : ""} onclick="return false;">
        <label style="color:black; font-size:6mm; margin-left: 20mm; margin-right: 10mm;">Negativo</label>
        <input type="checkbox" style="width: 6mm; height: 6mm;" ${esito === "Negativo" ? "checked" : ""} onclick="return false;">
    </div>
</div>
<div style="margin-top:15px">
    <label style="margin-left:5%; color:#14213d; font-size:8mm; font-weight:bold">Osservazioni primo esito:</label>
</div>
</div>

`;

        var HTMLStringContainer = document.createElement("div");
        HTMLStringContainer.innerHTML = mainPagina;
        document.body.appendChild(HTMLStringContainer);

        var HTMLStringContainer2 = document.createElement("div");
        HTMLStringContainer2.innerHTML = header;
        document.body.appendChild(HTMLStringContainer2);

        var HTMLStringContainer3 = document.createElement("div");
        HTMLStringContainer3.innerHTML = infRep2;
        document.body.appendChild(HTMLStringContainer3);

        var currentPage = 1;
        html2canvas(HTMLStringContainer,{scale:2,width:793.7007874}).then((canvas)=>{
            html2canvas(HTMLStringContainer2,{scale:2,width:793.7007874}).then((canvas2)=>{
                html2canvas(HTMLStringContainer3,{scale:2,width:793.7007874}).then((canvas3)=>{

                
                document.body.removeChild(HTMLStringContainer);
                document.body.removeChild(HTMLStringContainer2);
                document.body.removeChild(HTMLStringContainer3);

                const imgData = canvas.toDataURL('image/png')
                const imgHeader = canvas2.toDataURL('image/png')
                const imgData2 = canvas3.toDataURL('images/png');

                pdf.addImage(imgData,'JPEG',0,0,pdf.internal.pageSize.getWidth() ,pdf.internal.pageSize.getHeight());
                pdf.setFontSize(pdf.getFontSize()-3)
                pdf.text(pdf.internal.pageSize.getWidth()/2-pdf.getTextWidth(pdf.getNumberOfPages()+""),pdf.internal.pageSize.getHeight()-1,pdf.getNumberOfPages()+"")
                pdf.setFontSize(pdf.getFontSize()+3)

                pdf.addPage();
                pdf.addImage(imgHeader,'JPEG',0,0,pdf.internal.pageSize.getWidth(),pdf.internal.pageSize.getHeight());
                pdf.addImage(imgData2,'JPEG',0,30,pdf.internal.pageSize.getWidth(),pdf.internal.pageSize.getHeight());
                
                console.log("Ciao2:",pdf.internal.pageSize.getWidth());
                var reportTMP =manutenzione.Report;
                if(reportTMP===undefined){reportTMP = ""}
                var widthAvaiable = 210-10;
                var lines = pdf.splitTextToSize(reportTMP,widthAvaiable);
                console.log(lines)
                var currentLine = 0;
                var avaiableHeight = pdf.internal.pageSize.getHeight();
                avaiableHeight -= 60; // 60 è la dimensione in altezza che usa il canvas dell header
                var currentHeight = 60; //60 come sopra
                while(currentLine<lines.length){
                    if(avaiableHeight>=10){
                        pdf.text(10,currentHeight,lines[currentLine])
                        currentLine++;
                        avaiableHeight-=10;
                        currentHeight+=10;
                    }else{
                        pdf.setFontSize(pdf.getFontSize()-3)
                        pdf.text(pdf.internal.pageSize.getWidth()/2-pdf.getTextWidth(pdf.getNumberOfPages()+""),pdf.internal.pageSize.getHeight()-1,pdf.getNumberOfPages()+"")
                        pdf.setFontSize(pdf.getFontSize()+3)
                        pdf.addPage();
                        pdf.addImage(imgHeader,'JPEG',0,0,pdf.internal.pageSize.getWidth(),pdf.internal.pageSize.getHeight())
                        currentHeight = 35;
                        avaiableHeight = pdf.internal.pageSize.getHeight() - 60;
                    }
                }
                //var lines = pdf.text(10,60,reportTMP);

                // Stampo informazioni su gli elementi
                if(manutenzione.Type!=='doc'){
                    pdf.setFontSize(pdf.getFontSize()-3)
                    pdf.text(pdf.internal.pageSize.getWidth()/2-pdf.getTextWidth(pdf.getNumberOfPages()+""),pdf.internal.pageSize.getHeight()-1,pdf.getNumberOfPages()+"")
                    pdf.setFontSize(pdf.getFontSize()+3)
                    pdf.addPage();
                    pdf.addImage(imgHeader,'JPEG',0,0,pdf.internal.pageSize.getWidth(),pdf.internal.pageSize.getHeight());
                    pdf.setFontSize(pdf.getFontSize()+2)
                    pdf.text(10,35,"Esito elementi:")
                    pdf.text(pdf.internal.pageSize.getWidth()-pdf.getTextWidth("Positivo   Negativo")-2,35,"Positivo   Negativo");
                    pdf.setFontSize(pdf.getFontSize()-2)
                //var elementiFittizio = [{Nome:"Elemento1",Esito:true},{Nome:"Elemento2",Esito:false},{Nome:"Elemento3",Esito:undefined}]
                var currentHeight = 45;
                var i = 0;
                while(i<elementi.length){
                    if(currentHeight+10<pdf.internal.pageSize.getHeight()){
                        pdf.text(12,currentHeight,"•"+elementi[i].Nome);
                        if(elementi[i].Esito===undefined||(elementi[i].Esito==='Non specificato'&&manutenzione.Stato==='Pianificata')){ // -5 altrimenti esce sballata la casella non so perchè
                            generaCasellaPositivo(pdf,currentHeight-5,false)
                            generaCasellaNegativo(pdf,currentHeight-5,false)

                        }else if(elementi[i].Esito === "Positivo"){
                            generaCasellaPositivo(pdf,currentHeight-5,true)
                            generaCasellaNegativo(pdf,currentHeight-5,false)
                        }else{
                            generaCasellaPositivo(pdf,currentHeight-5,false)
                            generaCasellaNegativo(pdf,currentHeight-5,true)
                        }
                        currentHeight+=10;
                        i++;
                    }else{
                        pdf.setFontSize(pdf.getFontSize()-3)
                        pdf.text(pdf.internal.pageSize.getWidth()/2-pdf.getTextWidth(pdf.getNumberOfPages()+""),pdf.internal.pageSize.getHeight()-1,pdf.getNumberOfPages()+"")
                        pdf.setFontSize(pdf.getFontSize()+3)
                        pdf.addPage();
                        pdf.addImage(imgHeader,'JPEG',0,0,pdf.internal.pageSize.getWidth(),pdf.internal.pageSize.getHeight())
                        currentHeight = 35;
                    }
                }
            }

            if(sottoscadenze.length>0){
                // Aggiungi la pagina dei sottointerventi (prima o dopo gli elementi)
                pdf.addPage();
                pdf.addImage(imgHeader, 'JPEG', 0, 0, width, height);
                pdf.text(10, 35, "Esito Sottointerventi:");
                pdf.text(pdf.internal.pageSize.getWidth()-pdf.getTextWidth("Positivo   Negativo")-6,35,"Positivo   Negativo");

                currentHeight = 45; // Riparte dall'altezza iniziale per una nuova sezione
                const lineHeight = 10; // Altezza per ogni riga

                for (let i = 0; i < sottoscadenze.length; i++) {
                    if (currentHeight + 10 < height) {
                        pdf.setFont("Helvetica", "bold");
                        pdf.text(12, currentHeight, `• ${sottoscadenze[i].Titolo}`);
                        pdf.setFont("Helvetica", "normal");

                        if (sottoscadenze[i].Esito === undefined||elementi[i].Esito==='Non specificato') {
                            generaCasellaPositivo(pdf, currentHeight - 5, false);
                            generaCasellaNegativo(pdf, currentHeight - 5, false);
                        } else if (sottoscadenze[i].Esito === "Positivo") {
                            generaCasellaPositivo(pdf, currentHeight - 5, true);
                            generaCasellaNegativo(pdf, currentHeight - 5, false);
                        } else {
                            generaCasellaPositivo(pdf, currentHeight - 5, false);
                            generaCasellaNegativo(pdf, currentHeight - 5, true);
                        }
                        currentHeight += lineHeight;
                        // Descrizione del sottointervento
                        if (sottoscadenze[i].Descrizione) {
                            pdf.setFontSize(pdf.getFontSize() - 2);
                            pdf.setFont("Helvetica", "bold");
                            pdf.text(17, currentHeight, `Descrizione:`);
                            pdf.setFont("Helvetica", "normal");
                            currentHeight += 5;
                            pdf.setFontSize(pdf.getFontSize() - 3);
                            pdf.text(17, currentHeight, `${sottoscadenze[i].Descrizione}`);
                            currentHeight += lineHeight;
                            pdf.setFontSize(pdf.getFontSize() + 3);
                        }
                         // Report del sottointervento
                         if (sottoscadenze[i].Report) {
                            pdf.setFont("Helvetica", "bold");
                            pdf.text(17, currentHeight, `Osservazioni:`);
                            pdf.setFont("Helvetica", "normal");
                            currentHeight += 5;
                            pdf.setFontSize(pdf.getFontSize() - 3);
                            pdf.text(17, currentHeight, `${sottoscadenze[i].Report}`);
                            currentHeight += lineHeight;
                            pdf.setFontSize(pdf.getFontSize() + 5);
                        }
                        if(sottoscadenze[i].Elementi.length>0){
                            pdf.setFont("Helvetica", "bold");
                            pdf.text(17, currentHeight, `Esito elementi:`);

                            pdf.setFont("Helvetica", "normal");
                            pdf.setFontSize(pdf.getFontSize() - 3);

                            sottoscadenze[i].Elementi.forEach((elem)=>{
                                currentHeight += 7;
                                pdf.text(17, currentHeight, `${elem.Nome}`);
                                if(elem.Esito==='Non specificato'||elementi[i].Esito==='Non specificato'){ // -5 altrimenti esce sballata la casella non so perchè
                                    generaCasellaPositivo(pdf,currentHeight-5,true)
                                    generaCasellaNegativo(pdf,currentHeight-5,false)
        
                                }else if(elem.Esito === "Positivo"){
                                    generaCasellaPositivo(pdf,currentHeight-5,true)
                                    generaCasellaNegativo(pdf,currentHeight-5,false)
                                }else{
                                    generaCasellaPositivo(pdf,currentHeight-5,false)
                                    generaCasellaNegativo(pdf,currentHeight-5,true)
                                }

                            })
                            currentHeight += 20;
                            pdf.setFontSize(pdf.getFontSize() + 3);

                        }
                    } else {
                        pdf.addPage();
                        pdf.addImage(imgHeader, 'JPEG', 0, 0, width, height);
                        pdf.text(10, 35, "Esito Sottointerventi:");
                pdf.text(pdf.internal.pageSize.getWidth()-pdf.getTextWidth("Positivo   Negativo")-6,35,"Positivo   Negativo");
                        currentHeight = 45;
                        
                        i=i-1
                    }
                }
            }
                pdf.setFontSize(pdf.getFontSize()-3)
                pdf.text(pdf.internal.pageSize.getWidth()/2-pdf.getTextWidth(pdf.getNumberOfPages()+""),pdf.internal.pageSize.getHeight()-1,pdf.getNumberOfPages()+"")
                pdf.setFontSize(pdf.getFontSize()+3)
                pdf.save(manutenzione.Titolo+'.pdf');
                setLoading(false)
            })
        })
        })
        

    }

    function generaCasellaNegativo(pdf,height,spuntata){
        var width= pdf.internal.pageSize.getWidth();
        pdf.rect(width-16, height, 4, 4);
        if(spuntata){
            pdf.line(width-16,height,width-12,height+4)
            pdf.line(width-16,height+4,width-12,height);
        }
        //pdf.line(width-5, currentHeight, width-1, currentHeight-4);
        //pdf.line(xNeg+pdf.getTextWidth("Negativo")/2, currentHeight, xNeg+pdf.getTextWidth("Negativo")/2+4, currentHeight-4);
    }

    function generaCasellaPositivo(pdf,height,spuntata){
        var width= pdf.internal.pageSize.getWidth();

        pdf.rect(width-45, height, 4, 4);
        if(spuntata){
            pdf.line(width-45,height,width-41,height+4)
            pdf.line(width-45,height+4,width-41,height);
        }
    }

    const generaPdfIntervento=async (intervento,operatore,elementi,sottointerventi,setLoading)=>{
        console.log(intervento);
        console.log(elementi)
        const pdf = new jsPDF('p','mm','a4',true);
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();

        var numTelCliente = '';
        var esito = intervento.Esito?intervento.Esito:'Non definito'
        var sede = intervento.SedeName?intervento.SedeName!==''?intervento.SedeName:intervento.Sede_cli?intervento.Sede_cli:intervento.Sede:intervento.Sede_cli?intervento.Sede_cli:intervento.Sede

        if(intervento.Numero_telefono_cli!==undefined){numTelCliente=intervento.Numero_telefono_cli}

        const hour = intervento.Hour?' - '+intervento.Hour:''

        //sezione riepilogo intervento
        var mainPagina = '<html><head><style>.custom {border:0.01px solid black;border-collapse:collapse;}</style></head><body>'+
        '<div style="width:'+pdf.internal.pageSize.getWidth()+'mm;height:29.7cm;">'+
        '<div style="justify-content: space-between;display:flex;flex-direction:row;height:2cm;width:90%;margin-left:auto;margin-right:auto;">'+ 
        '<div style="height:100%;margin-left:0;padding-top:4mm">'+
                '<label style="font-size:8mm;font-weight:bold;color:#192bc2">RIEPILOGO INTERVENTO</label>'+
                '</br>'+
                '<label style="font-size:4mm;color:grey">'+intervento.Titolo+'</label>'+
            '</div>'+
            '<img style=width:30%;height:100%;align-self:flex-end;margin-right:0;margin-top:0;margin-bottom:auto" src="'+mysicLungo+'"/>'+
        '</div>'+
        '<div style="width:90%  ;border:1px solid lightgrey;margin-left:auto;margin-right:auto;margin-bottom:20px;margin-top:20px"></div>'+
        '<table class="custom" style="table-layout:fixed;width:90%;height:20%;margin-left:auto;margin-right:auto">'+
            '<colgroup>'+
                '<col span="1" style="width:20%">'+
                '<col span="1" style="width:80%">'+
            '</colgroup>'+
            '<tbody>'+
                '<tr>'+
                    '<th class="custom" colspan="2"><label style="color:#14213d;font-size:4mm;font-weight:bold">Anagrafica cliente</label></th>'+
                '</tr>'+
                '<tr>'+
                   '<th class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:grey">Ragione sociale</label></th>'+
                    '<td class="custom" style="text-align:left;" ><label style="margin-left:5px;font-size:3.2mm;color:black">'+intervento.Nome_Azienda+'</label></td>'+
                '</tr>'+
                '<tr>'+
                    '<th class="custom"  style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Nome e Cognome</label></th>'+
                    '<td class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:black">'+intervento.Nome+' '+intervento.Cognome+'</label></td>'+
                '</tr>'+
                '<tr>'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Sede di intervento</label></th>'+
                    '<td class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:black">'+sede+'</label></td>'+
                '</tr>'+
                '<tr>'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Email e telefono</label></th>'+
                    '<td class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:black">'+intervento.Cliente+' '+ numTelCliente +'</label></td>'+
                '</tr>'+
            '</tbody>'+
        '</table>'+
        '<div style="width:90%;border:1px solid lightgrey;margin-left:auto;margin-right:auto;margin-bottom:20px;margin-top:20px"></div>'+
            '<table class="custom" style="table-layout:fixed;width:90%;height:20%;margin-left:auto;margin-right:auto">'+
            '<colgroup>'+
                '<col span="1" style="width:20%">'+
                '<col span="1" style="width:80%">'+
            '</colgroup>'+
            '<tbody>'+
                '<tr>'+
                    '<th colspan="2" class="custom"><label style="color:#14213d;font-size:4mm;font-weight:bold">Anagrafica Operatore</label></th>'+
                '</tr>'+
                '<tr style="">'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Ragione sociale</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+operatore.Nome_Azienda+'</label></td>'+
                '</tr>'+
                '<tr style="">'+
                    '<th class="custom"  style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Nome e Cognome</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+operatore.Nome+' '+operatore.Cognome+'</label></td>'+
                '</tr>'+
                '<tr style="">'+
                    '<th class="custom" style="text-align:left;k"><label style="margin-left:5px;font-size:3.2mm;color:grey">Indirizzo Sede</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+operatore.Indirizzo+' '+operatore.Sede+'</label></td>'+
                '</tr>'+
                '<tr style="k">'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Email e telefono</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+operatore.Email+' '+operatore.Telefono+'</label></td>'+
                '</tr>'+
            '</tbody>'+
        '</table>'+
               '<div style="width:90%;border:1px solid lightgrey;margin-left:auto;margin-right:auto;margin-bottom:20px;margin-top:20px"></div>'+
            '<table class="custom" style="table-layout:fixed;width:90%;height:20%; margin-left:auto;margin-right:auto">'+
            '<colgroup>'+
                '<col span="1" style="width:20%">'+
                '<col span="1" style="width:80%">'+
            '</colgroup>'+
            '<tbody>'+
                '<tr>'+
                    '<th class="custom" colspan="2"><label style="color:#14213d;font-size:4mm;font-weight:bold">Parametri Intervento</label></th>'+
                '</tr>'+
                '<tr style="">'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Data</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+intervento.Data+hour+'</label></td>'+
                '</tr>'+
               '<tr style="">'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Soggetto</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+elementi[0].Nome+'</label></td>'+
                '</tr>'+
                '<tr style="">'+
                   ' <th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Stato'+
                    '</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+intervento.Stato+'</label></td>'+
                '</tr>'+
                '<tr style="">'+
                    '<th class="custom" style="text-align:left;"><label style="margin-left:5px;font-size:3.2mm;color:grey">Descrizione</label></th>'+
                    '<td class="custom" style="text-align:left"><label style="margin-left:5px;font-size:3.2mm;color:black">'+intervento.Descrizione+'</label></td>'+
                '</tr>'+
            '</tbody>'+
        '</table>'+
    '</div></body></html>';


    //header html
    var header = '<div style="width:'+pdf.internal.pageSize.getWidth()+'mm;height:29.7cm;">'+
        '<div style="justify-content: space-between;display:flex;flex-direction:row;height:2cm;width:90%;margin-left:auto;margin-right:auto;">'+ 
        '<div style="height:100%;margin-left:0;padding-top:4mm">'+
                '<label style="font-size:8mm;font-weight:bold;color:#192bc2">RIEPILOGO INTERVENTO</label>'+
                '</br>'+
                '<label style="font-size:4mm;color:grey">'+intervento.Titolo+'</label>'+
            '</div>'+
            '<img style=width:30%;height:100%;align-self:flex-end;margin-right:0;margin-top:0;margin-bottom:auto" src="'+mysicLungo+'"/>'+
        '</div><div style="width:90%  ;border:1px solid lightgrey;margin-left:auto;margin-right:auto;margin-bottom:20px;margin-top:20px"></div>'+
        '</div>'

        //esito complessivo e osservazioni
        var infRep2 = `
<div style="width:${pdf.internal.pageSize.getWidth()}mm; height:29.7cm">
<div style="display: flex; justify-content: space-between; align-items: center;">
    <label style="margin-left:5%; color:#14213d; font-size:8mm; font-weight:bold">Esito complessivo:</label>
    <div style="display: flex; align-items: center;">
        <label style="color:black; font-size:6mm; margin-right: 10mm;">Positivo</label>
        <input type="checkbox" style="width: 6mm; height: 6mm;" ${esito === "Positivo" ? "checked" : ""} onclick="return false;">
        <label style="color:black; font-size:6mm; margin-left: 20mm; margin-right: 10mm;">Negativo</label>
        <input type="checkbox" style="width: 6mm; height: 6mm;" ${esito === "Negativo" ? "checked" : ""} onclick="return false;">
    </div>
</div>
<div style="margin-top:15px">
    <label style="margin-left:5%; color:#14213d; font-size:8mm; font-weight:bold">Osservazioni primo esito:</label>
</div>
</div>

`;


        //crea contenitori HTML

        var HTMLStringContainer = document.createElement("div");
        HTMLStringContainer.innerHTML = mainPagina;
        document.body.appendChild(HTMLStringContainer);

    
        var HTMLStringContainer2 = document.createElement("div");
        HTMLStringContainer2.innerHTML = header;
        document.body.appendChild(HTMLStringContainer2);

        var HTMLStringContainer3 = document.createElement("div");
        HTMLStringContainer3.innerHTML = infRep2;
        document.body.appendChild(HTMLStringContainer3);

        //converte le sezioni html in canvas
        html2canvas(HTMLStringContainer,{scale:2,width:793.7007874}).then((canvas)=>{
            html2canvas(HTMLStringContainer2,{scale:2,width:793.7007874}).then((canvas2)=>{
                html2canvas(HTMLStringContainer3,{scale:2,width:793.7007874}).then((canvas3)=>{

                // Rimuovi i contenitori HTML dal DOM
                document.body.removeChild(HTMLStringContainer);
                document.body.removeChild(HTMLStringContainer2);
                document.body.removeChild(HTMLStringContainer3);


                 // Aggiungi le immagini al PDF
                const imgData = canvas.toDataURL('image/png')
                const imgHeader = canvas2.toDataURL('image/png')
                const imgData2 = canvas3.toDataURL('images/png');

                pdf.addImage(imgData,'JPEG',0,0,pdf.internal.pageSize.getWidth() ,pdf.internal.pageSize.getHeight());
                pdf.setFontSize(pdf.getFontSize()-3)
                pdf.text(pdf.internal.pageSize.getWidth()/2-pdf.getTextWidth(pdf.getNumberOfPages()+""),pdf.internal.pageSize.getHeight()-1,pdf.getNumberOfPages()+"")
                pdf.setFontSize(pdf.getFontSize()+3)

                pdf.addPage();
                pdf.addImage(imgHeader,'JPEG',0,0,pdf.internal.pageSize.getWidth(),pdf.internal.pageSize.getHeight());
                pdf.addImage(imgData2,'JPEG',0,30,pdf.internal.pageSize.getWidth(),pdf.internal.pageSize.getHeight());
                
                // Aggiungi il report dell'intervento
                let reportTMP = intervento.Report || "";
                var widthAvaiable = 210-10;
                var lines = pdf.splitTextToSize(reportTMP,widthAvaiable);

                console.log(lines)
                var currentLine = 0;
                var avaiableHeight = pdf.internal.pageSize.getHeight();
                avaiableHeight -= 60; // 60 è la dimensione in altezza che usa il canvas dell header

                var currentHeight = 59; //60 come sopra

                while(currentLine<lines.length){
                    if(avaiableHeight>=10){
                        pdf.text(10,currentHeight,lines[currentLine])
                        currentLine++;
                        avaiableHeight-=10;
                        currentHeight+=10;
                    }else{
                        pdf.setFontSize(pdf.getFontSize()-3)
                        pdf.text(pdf.internal.pageSize.getWidth()/2-pdf.getTextWidth(pdf.getNumberOfPages()+""),pdf.internal.pageSize.getHeight()-1,pdf.getNumberOfPages()+"")
                        pdf.setFontSize(pdf.getFontSize()+3)
                        pdf.addPage();
                        pdf.addImage(imgHeader,'JPEG',0,0,pdf.internal.pageSize.getWidth(),pdf.internal.pageSize.getHeight())
                        currentHeight = 35;
                        avaiableHeight = pdf.internal.pageSize.getHeight() - 60;
                    }
                }
                //var lines = pdf.text(10,60,reportTMP);

                // Stampo informazioni su gli elementi
                pdf.setFontSize(pdf.getFontSize()-3)
                pdf.text(pdf.internal.pageSize.getWidth()/2-pdf.getTextWidth(pdf.getNumberOfPages()+""),pdf.internal.pageSize.getHeight()-1,pdf.getNumberOfPages()+"")
                pdf.setFontSize(pdf.getFontSize()+3)
                pdf.addPage();
                pdf.addImage(imgHeader,'JPEG',0,0,pdf.internal.pageSize.getWidth(),pdf.internal.pageSize.getHeight());
                pdf.setFontSize(pdf.getFontSize()+2)
                pdf.text(10,35,"Esito elementi:")
                pdf.text(pdf.internal.pageSize.getWidth()-pdf.getTextWidth("Positivo   Negativo")-2,35,"Positivo   Negativo");
                pdf.setFontSize(pdf.getFontSize()-2)
                //var elementiFittizio = [{Nome:"Elemento1",Esito:true},{Nome:"Elemento2",Esito:false},{Nome:"Elemento3",Esito:undefined}]
                

                currentHeight = 45;
                var i = 0;
                while(i<elementi.length){
                    if(currentHeight+10<pdf.internal.pageSize.getHeight()){
                        pdf.text(12,currentHeight,"•"+elementi[i].Nome);
                        console.log(elementi[i].Esito)
                        if(elementi[i].Esito===undefined||elementi[i].Esito==='Non specificato'){ // -5 altrimenti esce sballata la casella non so perchè
                            generaCasellaPositivo(pdf,currentHeight-5,false)
                            generaCasellaNegativo(pdf,currentHeight-5,false)

                        }else if(elementi[i].Esito === "Positivo"){
                            generaCasellaPositivo(pdf,currentHeight-5,true)
                            generaCasellaNegativo(pdf,currentHeight-5,false)
                        }else{
                            generaCasellaPositivo(pdf,currentHeight-5,false)
                            generaCasellaNegativo(pdf,currentHeight-5,true)
                        }
                        currentHeight+=10;
                        i++;
                    }else{
                        pdf.setFontSize(pdf.getFontSize()-3)
                        pdf.text(pdf.internal.pageSize.getWidth()/2-pdf.getTextWidth(pdf.getNumberOfPages()+""),pdf.internal.pageSize.getHeight()-1,pdf.getNumberOfPages()+"")
                        pdf.setFontSize(pdf.getFontSize()+3)
                        pdf.addPage();
                        pdf.addImage(imgHeader,'JPEG',0,0,pdf.internal.pageSize.getWidth(),pdf.internal.pageSize.getHeight())
                        currentHeight = 35;
                    }
                }
                if(sottointerventi.length>0){
                // Aggiungi la pagina dei sottointerventi (prima o dopo gli elementi)
                pdf.addPage();
                pdf.addImage(imgHeader, 'JPEG', 0, 0, width, height);
                pdf.text(10, 35, "Esito Sottointerventi:");
                pdf.text(pdf.internal.pageSize.getWidth()-pdf.getTextWidth("Positivo   Negativo")-6,35,"Positivo   Negativo");

                currentHeight = 45; // Riparte dall'altezza iniziale per una nuova sezione
                const lineHeight = 10; // Altezza per ogni riga

                for (let i = 0; i < sottointerventi.length; i++) {
                    if (currentHeight + 10 < height) {
                        pdf.setFont("Helvetica", "bold");
                        pdf.text(12, currentHeight, `• ${sottointerventi[i].Titolo}`);
                        pdf.setFont("Helvetica", "normal");

                        if (sottointerventi[i].Esito === undefined||elementi[i].Esito==='Non specificato') {
                            generaCasellaPositivo(pdf, currentHeight - 5, false);
                            generaCasellaNegativo(pdf, currentHeight - 5, false);
                        } else if (sottointerventi[i].Esito === "Positivo") {
                            generaCasellaPositivo(pdf, currentHeight - 5, true);
                            generaCasellaNegativo(pdf, currentHeight - 5, false);
                        } else {
                            generaCasellaPositivo(pdf, currentHeight - 5, false);
                            generaCasellaNegativo(pdf, currentHeight - 5, true);
                        }
                        currentHeight += lineHeight;
                        // Descrizione del sottointervento
                        if (sottointerventi[i].Descrizione) {
                            pdf.setFontSize(pdf.getFontSize() - 2);
                            pdf.setFont("Helvetica", "bold");
                            pdf.text(17, currentHeight, `Descrizione:`);
                            pdf.setFont("Helvetica", "normal");
                            currentHeight += 5;
                            pdf.setFontSize(pdf.getFontSize() - 3);
                            pdf.text(17, currentHeight, `${sottointerventi[i].Descrizione}`);
                            currentHeight += lineHeight;
                            pdf.setFontSize(pdf.getFontSize() + 3);
                        }
                         // Report del sottointervento
                         if (sottointerventi[i].Report) {
                            pdf.setFont("Helvetica", "bold");
                            pdf.text(17, currentHeight, `Osservazioni:`);
                            pdf.setFont("Helvetica", "normal");
                            currentHeight += 5;
                            pdf.setFontSize(pdf.getFontSize() - 3);
                            pdf.text(17, currentHeight, `${sottointerventi[i].Report}`);
                            currentHeight += lineHeight;
                            pdf.setFontSize(pdf.getFontSize() + 5);
                        }
                    } else {
                        pdf.addPage();
                        pdf.addImage(imgHeader, 'JPEG', 0, 0, width, height);
                        currentHeight = 35;
                    }
                }
            }

                pdf.setFontSize(pdf.getFontSize()-3)
                pdf.text(pdf.internal.pageSize.getWidth()/2-pdf.getTextWidth(pdf.getNumberOfPages()+""),pdf.internal.pageSize.getHeight()-1,pdf.getNumberOfPages()+"")
                pdf.setFontSize(pdf.getFontSize()+3)
                pdf.save(intervento.Titolo+'.pdf');
                setLoading(false)
            })
        })
        })
        

    }

    return{generaPdfManutenzione,generaPdfIntervento}
}


export default GenerazionePdf();