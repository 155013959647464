import React , { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente,getScadenza,checkWriteS } from "../../EngineClienti/engineClienti";
import Loading from '../../loading';
import Dettagli from '../DettagliScadenza/Dettagli'
import Cliente from '../DettagliScadenza/Cliente'
import Manutentore from '../DettagliScadenza/Manutentore';
import Allegati from '../DettagliScadenza/Allegati';
import Elementi from '../DettagliScadenza/Elementi';
import Operazioni from '../DettagliScadenza/Operazioni';
import NavBar2 from '../../NavBar/NavBar2'
import LoadingScreen from '../../LoadingScreen';

const DettagliScadenzaOff = ({history}) => {
    const auth = firebase.auth();
    const db = firebase.firestore();
    const {id} = useParams()

    const [user,setUser] = useState(null)
    const [scadenza,setScadenza] = useState('')
    const [section,setSection] = useState(0)
    const [cliente,setCliente] = useState(null)
    const [manutentore,setManutentore] = useState(null)
    const [elementi,setElementi] = useState([])

    const [loading,setLoading] = useState(false)


    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      useEffect(()=>{
        if(user!==null){
         getScadenza(db,id,setScadenza)      
      }
    
      },[user])

      function getElementi(){
        if(scadenza.Elementi){
          setElementi([])
          scadenza.Elementi.forEach((elemento)=>{
            console.log(elemento)
            var path = elemento.Elemento?elemento.Elemento:elemento

              db.doc(path).get().then((doczz)=>{
                db.collection('Elementi').doc(doczz.data().ref).get().then((docz)=>{
                  console.log(docz.data())
                  setElementi((prev)=>{
                    return [
                      ...prev,
                      {
                        Id: docz.id,
                        ...docz.data(),
                        ...elemento
                      }
                    ]
                  })
                })
                
              })
            
           
          })
        }
      }

      function getUtenteD(em,i){
        db.collection('Users').doc(em).get().then((us)=>{
            console.log(us.data())
            if(i===0){
                setCliente(us.data())

            }else{
                setManutentore(us.data())
            }
        })
      }

      function changeSection(i){
        if(i===1){
          if(elementi.length>0){
              setSection(1)

          }else{
              getElementi()
              setSection(1)
          }
          
      }
        if(i===2){
            if(cliente!==null){
                setSection(2)

            }else{
                getUtenteD(scadenza.Cliente,0)
                setSection(2)
            }
            
        }
        if(i===3){
            if(manutentore!==null){
                setSection(3)

            }else{
                getUtenteD(scadenza.Operatore,1)
                setSection(3)
            }
            
        }
      }

      

  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
            {loading===true?<LoadingScreen text={'Generazione PDF in corso, attendere prego...'}/>:null}

      <NavBar2/>
         <div
        style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
            <div  style={{  width: "70vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{whiteSpace: 'nowrap',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'70vw',margin:0}}>{scadenza?scadenza.Titolo:null}</h4>
          <h4
          className="sub-text-b"
        >
          DETTAGLI SCADENZA
        </h4>
          </div>

        </div>
        {scadenza!==null?
        <div className="box-shadow" style={{display:'flex',width:'80vw',backgroundColor:'white',marginTop:'1.7rem',borderRadius:'15px',marginLeft:'3rem'}}>
        <div style={{alignItems:'center',height:'80vh',backgroundColor:'white',borderBottomLeftRadius:'15px',borderTopLeftRadius:'15px',width:'10rem',border:'1px solid #7d8da1'}}>
        <button onClick={()=>setSection(0)} style={{borderTopLeftRadius:'15px'}} className={section===0?"section-style-on":'section-style-off'}>Dettagli</button><br/>
        {scadenza.Elementi?<button onClick={()=>changeSection(1)} className={section===1?"section-style-on":'section-style-off'}>Elementi</button>:null}
        <button onClick={()=>changeSection(2)} className={section===2?"section-style-on":'section-style-off'}>Cliente</button><br/>
        <button onClick={()=>changeSection(3)} className={section===3?"section-style-on":'section-style-off'}>Manutentore</button>
        <button onClick={()=>setSection(4)} className={section===4?"section-style-on":'section-style-off'}>Allegati</button>
        {scadenza.Stato!=='Conclusa'&&scadenza.Stato!=='Scaduto'?
        user!==null?checkWriteS(user.Permissions)===true?<button onClick={()=>setSection(5)} className={section===5?"section-style-on":'section-style-off'}>{scadenza.Stato==='In attesa'?'Pianifica':'Evadi'}</button>:null:null:null}
        </div>
       <div>
        {section===0?<Dettagli id={id}  user={user} scadenza={scadenza} setLoading={setLoading}/>:null}
        {section===1?<Elementi id={id} history={history}  user={user} elementi={elementi} scadenza={scadenza}/>:null}
        {section===2?<Cliente id={id} cliente={cliente} user={user} scadenza={scadenza}/>:null}
        {section===3?<Manutentore id={id} manutentore={manutentore} user={user} scadenza={scadenza}/>:null}
        {section===4?<Allegati  user={user} scadenza={scadenza} setScadenza={setScadenza}/>:null}
        {section===5?<Operazioni  user={user} scadenza={scadenza} setSection={setSection} setScadenza={setScadenza}/>:null}


        </div>
        </div>:<Loading/>}
      </div>

    </div>
  )
}

export default DettagliScadenzaOff