import React , { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente,getSede } from "../../EngineClienti/engineClienti";
import { getDocumento } from '../../EngineOffritore/engineOffritore';
import Loading from '../../loading';
import NavBar2 from '../../NavBar/NavBar2';

const AggiungiScadenzaOffritore = ({history}) => {

    const auth = firebase.auth();
    const db = firebase.firestore();
    const {id} = useParams()

    const [user,setUser] = useState(null)
    const [documento,setDocumento] = useState(null)
    const [sede,setSede] = useState(null)

    const [selectedData,setSelectedData] = useState('')
    const [description,setDescription] = useState('')


    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      useEffect(()=>{
        if(user!==null){
         getDocumento(db,id,setDocumento)
      }
    
      },[user])

      useEffect(()=>{
        if(documento!==null){
         getSede(db,documento.Autore,documento.Sede,setSede)   
      }
    
      },[documento])

      function addScadenza(){
        if(selectedData!==''&&description!==''){
    
          const dateSplitted = selectedData.split("-");
          const year = dateSplitted[0];
          const month = dateSplitted[1];
          const day= dateSplitted[2];
          const hours = '12';
          const minutes = '00';
          const arr = JSON.parse(sessionStorage.getItem('documenti'));

          const ref = db
            .collection("Manutenzioni")
            .doc();
    
          const idR = ref.id;

          if(arr!==null){
            var indexD = arr.findIndex((e)=>e.Id===id)
            arr[indexD].Scadenza = selectedData
            arr[indexD].Act_ID = idR
            arr[indexD].Manutentore = user.Email

            sessionStorage.setItem('documenti', JSON.stringify(arr));
          }           
      
              db.collection("Documenti")
                .doc(id)
                .update({
                  Scadenza: selectedData,
                  Act_ID: idR,
                  Manutentore: user.Email
                }); //aggiorna doc
                //incremente scad manutentore
                db.collection('Users').doc(user.Email).update({
                  Current_Scad: firebase.firestore.FieldValue.increment(1)
                })
                //incremente scad manutentore su clienti
                db.collection("Users")
                .doc(user.Email)
                .collection("Clienti")
                .doc(documento.Autore).update({
                  Man: firebase.firestore.FieldValue.increment(1)
                })
                //incrementa scad cliente
                db.collection('Users').doc(documento.Autore).update({
                  Current_Scad: firebase.firestore.FieldValue.increment(1)
                })
                //incrementa scad cliente su sedi
                db.collection('Users').doc(documento.Autore).collection("Sedi")
                .doc(documento.Sede).update({
                  Man: firebase.firestore.FieldValue.increment(1)
                })
    
              db.collection("Users")
                .doc(documento.Autore)
                .collection("Sedi")
                .doc(documento.Sede)
                .collection('Manutenzioni')
                .doc(idR)
                .set({
                 ref: idR
                }); //attività cliente
    
              db.collection("Users")
                .doc(documento.Autore)
                .collection("Annotazioni")
                .doc(idR)
                .set({
                  Day: day,
                  Description: '<a style={{display: "table-cell"}} target="_blank" href="'+documento.URL+'">Visualizza</a>',
                  Month: month,
                  Subject: "Scadenza: " + documento.NomeFile,
                  Year: year,
                  startHours: hours,
                  startMinutes: minutes,
                  endHours: hours,
                  endMinutes: minutes,
                  Cliente: documento.Autore,
                  Fornitore: user.Email
                });
                var datee = new Date();
                var month2 = ('0'+(datee.getMonth()+1)).slice(-2);
                var minutess =  ('0'+(datee.getMinutes())).slice(-2);
    
                  var dataFormattata = datee.getDate()+"-"+month2+"-"+datee.getFullYear()+" "+(datee.getHours())+":"+minutess;
              db.collection("Users").doc(documento.Autore).collection("Notifiche").doc().set({
                Nome_Azienda:user.Nome_Azienda,
                Nome_Cognome:user.Nome+" "+user.Cognome,
                Email:user.Email,
                Messaggio:"ha aggiunto una scadenza",
                Data:dataFormattata,
                Oggetto: documento.NomeFile
              })
                db.collection("Users")
                  .doc(user.Email)
                  .collection("Clienti")
                  .doc(documento.Autore)
                .collection('Manutenzioni')
                  .doc(idR)
                  .set({
                    ref: idR,
                  }); //attivita offritore
    
                  db.collection('Users').doc(documento.Autore).get().then((doczzz)=>{
                    db.collection('Manutenzioni').doc(idR).set({
                      Cliente: doczzz.data().Email,
                      Nome: doczzz.data().Nome,
                      Cognome: doczzz.data().Cognome,
                      Data: selectedData,
                      Descrizione: description,
                      Oggetto: "Documento: " + documento.URL,
                      Stato: "Pianificata",
                      Indirizzo: sede.Indirizzo,
                      Type:'doc',
                      Nome_AziendaCli:  doczzz.data().Nome_Azienda,
                      Nome_AziendaOff: user.Nome_Azienda,
                      Titolo: "Scadenza: " + documento.NomeFile,
                      Sede: documento.Sede,
                      Categoria: documento.Categoria,
                      Macrocategoria: '',
                      Operatore: user.Email?user.Email:null,
                      ID: idR,
                      Priorità:'Alta',
      
                    })
                  })

                db.collection("Users")
                  .doc(user.Email)
                  .collection("Annotazioni")
                  .doc(idR)
                  .set({
                    Day: day,
                    Description: '<a style={{display: "table-cell"}} target="_blank" href="'+documento.URL+'">Visualizza</a>',
                    Month: month,
                    Subject: "Scadenza: " +documento.NomeFile,
                    Year: year,
                    startHours: hours,
                    startMinutes: minutes,
                    endHours: hours,
                    endMinutes: minutes,
                    Indirizzo: sede.Indirizzo,
                    Cliente: documento.Autore,
                  Fornitore: user.Email?user.Email:""
                  });
            
           
            alert('Scadenza aggiunta, controlla il calendario per visualizzarla')
            history.goBack()
          
      }else{
        alert("Errore, inserire tutti i campi.")
      }
      }

  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
      <NavBar2/>
        <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
        <div  style={{  width: "30vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>IMPOSTA SCADENZA</h4>
          <h4
          className="sub-text-b"
        >
          {documento?documento.NomeFile:null} - {documento?documento.Sede+' ('+documento.Categoria+')':null}
        </h4>
          </div>

        </div>
        <div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'30rem'}}>

{/*SELEZIONE DATA */}
<h4  className="sub-text-b" >Seleziona data:</h4>
        <input type='date' style={{marginTop:'0.5rem'}} className="input-style"
         onChange={(e) => {
            setSelectedData(e.target.value);
          }} placeholder="Inserisci..."/>
            <br/>
            {/*SELEZIONE DESCRIZIONE */}
<h4  className="sub-text-b" style={{marginTop:'1rem'}}>Inserisci descrizione:</h4>
        <input type='text' style={{marginTop:'0.5rem'}} className="input-style"
         onChange={(e) => {
            setDescription(e.target.value);
          }} placeholder="Inserisci descrizione..."/>
            <br/>
          <button className='main-button' style={{marginTop:'1rem'}} onClick={()=>addScadenza()}>Aggiungi</button>
        </div>
        </div>
        </div>
  )
}

export default AggiungiScadenzaOffritore