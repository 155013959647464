import React,{useState} from "react";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { IconButton,Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import firebase from "firebase/app";
import AddIcon from '@mui/icons-material/Add';

const Elementi = (props) => {
  return (
    <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
         {props.scadenza!==null?<div>
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <FilePresentIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Elementi in analisi</h4>
      </div>
      {props.elementi?props.elementi.length>0?props.elementi.map((all,n)=>(
        <div key={n} style={{marginTop:'1rem',borderBottom:'1px solid lightgrey'}}>
          <h4 className="normal-text2" onClick={()=>props.history.push(`/elementi/elemento/${all.Id}`)}  style={{maxWidth:'30rem',whiteSpace: 'nowrap',color:'#192bc2',fontWeight:'bold',fontSize:'1.1rem',
overflow: 'hidden',
textOverflow: 'ellipsis',cursor:'pointer'}}>{all.Nome}</h4>
     <h4 className="sub-text"  >Categoria: <b>{all.Categoria}</b></h4>
     <h4 className="sub-text"  >Sottocategoria: <b>{all.Macrocategoria}</b></h4>

          </div>
      )):<h4 className="medium-text" style={{marginTop:'1rem'}}>Nessun elemento presente</h4>:<h4 style={{marginTop:'1rem'}} className="medium-text">Nessun elemento presente</h4>}

      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>:null}</div>
  )
}

export default Elementi