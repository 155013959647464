import React,{useEffect, useState} from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import firebase from "firebase";
import { getUtente,formatData,checkDeleteS,checkWriteS } from '../EngineClienti/engineClienti';
import { getDocumenti } from '../EngineOffritore/engineOffritore';
import LoopIcon from '@material-ui/icons/Loop';
import '../general.css'
import pdf_img from '../images/doc-icon.png'
import media_img from '../images/media-icon.png'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteIcon from "@material-ui/icons/Delete";
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import AlarmIcon from '@mui/icons-material/Alarm';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import Condividi from '../Popups/Condividi';
import NavBar2 from '../NavBar/NavBar2'
import Notifiche from '../Notifiche/Notifiche';

const DocumentiOffritore = ({history}) => {

    const [clienti,setClienti] = useState([]) //lista sedi
    const [categorie,setCategorie] = useState([]) //lista categorie
    const [cartelle,setCartelle] = useState([]) //lista cartelle
    const [documenti, setDocumenti] = useState([]) //lista documenti
    const [user,setUser] = useState(null) //info utente 

    const [triggerCond,setTriggerCond] = useState(false)


    ///////FILTRI///////

    const [clienteF,setClienteF] = useState('default')
    const [categoriaF,setCategoriaF] = useState('default')
    const [cartellaF,setCartellaF] = useState('default')
    const [findInput,setFindInput] = useState('')
    const [hasExp,setHasExp] = useState(0) 


    const db = firebase.firestore()
    const auth = firebase.auth()
    const storage = firebase.storage();
    var storageRef = storage.ref();

    const listaD = JSON.parse(sessionStorage.getItem('documenti'));
    const listaC = JSON.parse(sessionStorage.getItem('categorie'));
    const listaCa = JSON.parse(sessionStorage.getItem('cartelle'));
    const listaCli = JSON.parse(sessionStorage.getItem('clienti'));

      //sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    useEffect(() => {
      if(user!==null){
      if(user.Current_Doc===documenti.length){
        sessionStorage.setItem('documenti', JSON.stringify(documenti));
        sessionStorage.setItem('categorie', JSON.stringify(categorie));
        sessionStorage.setItem('cartelle', JSON.stringify(cartelle));
        sessionStorage.setItem('clienti', JSON.stringify(clienti));
  
      }
    }  
    }, [documenti]);

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      useEffect(()=>{
        if(user!==null){
     
          if(listaD!==null&&listaC!==null&&listaCa!==null){
    
          if(listaD.length>0&&listaC.length>0&&listaCa.length>0&&listaCli.length>0){
            setCategorie(listaC)
            setDocumenti(listaD)
            setCartelle(listaCa)
            setClienti(listaCli)
          }else{
            getDocumenti(db,user.Email,setClienti,setCategorie,setCartelle,setDocumenti)
    
          }
        }else{
          getDocumenti(db,user.Email,setClienti,setCategorie,setCartelle,setDocumenti)
    
        }
      }
    
      },[user])

      const Buttons = () =>{

        return(
          <div style={{display:'flex',alignItems:'center',gap:'1.5rem'}}>
             <div>
            <h4 onClick={()=>history.push('/documentimanutentore/aggiungicategoria')}  className='text-buttons' >
                    AGGIUNGI CATEGORIA
                  </h4>
                  
            </div>
            <div>
            <h4 onClick={()=>history.push('/documentimanutentore/aggiungidocumento')}  className='text-buttons' >
                    AGGIUNGI DOCUMENTO
                  </h4>
                  
            </div>
          
          </div>
        )
      }

      function renderDoc(doc){
        var nome = doc.NomeFile?doc.NomeFile.toString().toLowerCase():''
        var scadenza = doc.Scadenza?doc.Scadenza.toString().toLowerCase():''
        var shared = doc.Shared?doc.Shared.toString().toLowerCase():''
    
        if((clienteF === "" ||clienteF === "default" || clienteF === null || doc.Autore === clienteF) &&
        (categoriaF === "" ||categoriaF === "default" ||
          categoriaF === null ||
          doc.Categoria === categoriaF) &&
          (hasExp===0||(hasExp===1&&doc.Scadenza!=='No')||(hasExp===2&&doc.Scadenza==='No'))
          &&
        (cartellaF === "" ||cartellaF === "default" ||
          cartellaF === null ||
          doc.Cartella===cartellaF)&&(findInput===''||findInput===null||nome.includes(findInput)||scadenza.includes(findInput)||shared.includes(findInput))){
            return true
          }else{
            return false
          }
      }
      function reGet (){
        sessionStorage.clear()
        getDocumenti(db,user.Email,setClienti,setCategorie,setCartelle,setDocumenti)
    
      }
      function downloadFile(url) {
        //funzione per aprire il documento
        window.open(url, "_blank");
      } //funzione che apre i file

      function returnNomeAzienda(name){

        var arr = [...clienti]
        var index = arr.findIndex((e)=>e.Email===name)

        return clienti[index].Nome_Azienda

      }

      function deleteDocuments(name, folder,storageref,index,key,cat,cart,ele) {
        //funzione per eliminare il documento
        if(window.confirm("Vuoi eliminare il documento "+name+"? Lo eliminerai anche a "+ returnNomeAzienda(ele.Autore) +".")){

          db.collection('Users').doc(user.Email).update({
            Current_Doc: firebase.firestore.FieldValue.increment(-1)
          }) //decrementa man
          db.collection('Users').doc(user.Email).collection('Clienti').doc(ele.Autore).update({
            Current_Doc: firebase.firestore.FieldValue.increment(-1)
          }) //decrementa cli su man
          db.collection('Users').doc(user.Email).collection('Clienti').doc(ele.Autore).collection('Elementi').doc(key).delete() //elimina ref su man

          db.collection('Users').doc(ele.Autore).get().then((userx)=>{ //prendi dati cli
            db.collection('Users').doc(ele.Autore).update({ 
              Current_MB: parseFloat(userx.data().Current_MB)-parseFloat(ele.Size),
              Current_Doc: firebase.firestore.FieldValue.increment(-1)
            }) //aggiorna valori cli
            db.collection('Users').doc(ele.Autore).collection('Categorie').doc(ele.Categoria).update({
              Doc: firebase.firestore.FieldValue.increment(-1)
            }) //aggiorna valore cat
            db.collection('Users').doc(ele.Autore).collection('Categorie').doc(ele.Categoria).collection('Cartelle').doc(ele.Cartella).update({
              Doc: firebase.firestore.FieldValue.increment(-1)
            }) //aggiorna valore cart
            db.collection('Users').doc(ele.Autore).collection('Categorie').doc(ele.Categoria).collection('Cartelle').doc(ele.Cartella)
            .collection('Documenti').doc(key).delete() //elimina ref

            db.collection('Documenti').doc(key).delete().then(()=>{ //elimina da cartella doc
              var deleteRef;
              if(storageref === undefined){
                deleteRef = storageRef.child(
                    ele.Autore + "/" + ele.Sede + "/" + ele.Cartella + "/" + key
              );
              }
              else{
                deleteRef = storageRef.child(storageref);
              }
              
              deleteRef.delete().then(()=>{ //elimina da storage
                if(ele.Act_ID!==null){
                  // Elimino man
                  db.collection("Users").doc(ele.Autore)
                  .collection("Sedi").doc(folder)
                  .collection("Manutenzioni").doc(ele.Act_ID)
                  .delete()
                  db.collection("Users").doc(user.Email)
                  .collection("Clienti").doc(ele.Autore)
                  .collection("Manutenzioni").doc(ele.Act_ID)
                  .delete()
                  db.collection('Manutenzioni').doc(ele.Act_ID).delete()
                  // Elimino annotazione
                  db.collection("Users").doc(user.Email)
                  .collection("Annotazioni").doc(ele.Act_ID)
                  .delete()
                                         }

                  const arr = [...documenti];
                  arr.splice(index,1)
                  setDocumenti(arr)
                  sessionStorage.setItem('documenti', JSON.stringify(arr));

                   alert("File eliminato con successo");                   
              })
            })



          })
      }
      } //funzione per eliminare i documenti
      function deleteExpiration(nomefile,keyy,sede,act_id,doc) {
        if(window.confirm("Vuoi eliminare la scadenza del documento "+nomefile+" ?")){


          db.collection('Users').doc(doc.Autore).collection('Sedi').doc(sede).collection('Manutenzioni').doc(act_id).delete().then(()=>{ //elimina att da cartella in utente
            db.collection('Manutenzioni').doc(act_id).delete().then(()=>{ //elimina att da cartella generale
              db.collection('Users').doc(user.Email).collection('Clienti').doc(doc.Autore).collection('Manutenzioni').doc(act_id).delete()
              db.collection('Users').doc(doc.Autore).collection('Annotazioni').doc(act_id).delete().then(()=>{ //elimina annotazione
                db.collection('Users').doc(user.Email).collection('Annotazioni').doc(act_id).delete().then(()=>{
                db.collection('Documenti').doc(keyy).update({
                  Scadenza:"No",
                  Act_ID: ''
                }).then(()=>{
                  var arr = [...documenti]
                  var indD = arr.findIndex((e)=>e.Id===keyy)
                  arr[indD].Scadenza = 'No'
                  sessionStorage.setItem('documenti', JSON.stringify(arr));

            
                setDocumenti(arr)

                  alert('Scadenza rimossa')
                })
              })
              })
            })
          })
        } 
      } //funzione per eliminare la scadernza

      const onSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction });
      };
    
      const sortedDocs = React.useMemo(() => {
        let sortableItems = [...documenti];
        if (sortConfig.key !== null) {
          sortableItems.sort((a, b) => {
            if (typeof a[sortConfig.key] === 'string') {
              return a[sortConfig.key].localeCompare(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            } else if (sortConfig.key === 'date') {
              return new Date(a[sortConfig.key]) - new Date(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            }else if (sortConfig.key === 'number') {
              return (a[sortConfig.key] - b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            } else {
              return 0;
            }
          });
        }
        return sortableItems;
      }, [documenti, sortConfig]);

  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
             {user!==null&&user!==null?<Notifiche user={user}/>:null}
            <NavBar2/>
        <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
        <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>DOCUMENTI</h4>
       
      </div>
        {user!==null?checkWriteS(user.Permissions)===true?<Buttons/>:null:null}
        <div className='box-shadow' style={{marginTop:'0.5rem',borderRadius:5,width:'75vw',backgroundColor:'white',height:'3rem',display:'flex',alignItems:'center'}}>
          <div style={{width:'7vw',height:'3rem',borderRight:'1px solid lightgrey',}}>
          <h4 className="normal-text" style={{marginLeft:'auto',marginRight:'auto',marginTop:'0.3rem',fontSize:'1rem',width:'fit-content'}}>
           {documenti.length}
          </h4>
          <h4 className="sub-text" style={{marginLeft:'auto',marginRight:'auto',fontSize:'0.8rem',width:'fit-content'}}>
          {'Documenti totali'}
          </h4>
          </div>
          <Tooltip title={hasExp===0?'Con e senza scadenza':hasExp===1?'Con scadenza':'Senza scadenza'}>
          <IconButton onClick={()=>hasExp===0?setHasExp(1):hasExp===1?setHasExp(2):setHasExp(0)} style={{width:'fit-content',marginLeft:5}}>
          <CalendarMonthIcon style={{color:hasExp===0?'grey':hasExp===1?'green':'red'}}/>
          </IconButton>
          </Tooltip>
          <select
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw'}}
                defaultValue={"default"}
                value={clienteF}
                className='input-style'
                onChange={(e) => {
                  setClienteF(e.target.value);
                  setCategoriaF('default');
                 setCartellaF('default');
                }}
              >
                <option value={"default"} disabled>
                  Seleziona cliente...
                </option>
                <option value={""}>Tutti</option>
                {clienti && clienti.length > 0 && clienti.map((cliente,n)=>{
                                  return <option key={n} value={cliente.Email}>{cliente.Nome_Azienda}</option>
                              })}
              </select>
             {/* */}
              <select
                className='input-style'
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw'}}
                defaultValue={"default"}
                value={categoriaF}
                onChange={(e) => {
                  setCategoriaF(e.target.value);
                  setCartellaF('default');
                }}
                name="categoria"
              >
                <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
                <option value={""}>Tutte</option>
                {categorie&&
                categorie.map((categoria,n) => {
                    if (
                      clienteF === "" ||clienteF === "default" ||
                      clienteF === null ||
                      categoria.Cliente===clienteF
                    ) {
                      return (
                        <option key={n} value={categoria.Nome}>
                          {categoria.Nome}
                        </option>
                      );
                    }else{
                      return null
                    }
                  })}
              </select>
             {/* */}
              <select
                className='input-style'
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw',width:'fit-content',maxWidth:'10rem'}}
                defaultValue={"default"}
                value={cartellaF}
                onChange={(e) =>
                   setCartellaF(e.target.value)
                }
                name="cartella"
              >
                <option value={"default"} disabled>
                  {'Seleziona sottocategoria...'}
                </option>
                <option value={""}>Tutte</option>
                {cartelle&&cartelle.map((cartella,n) => {
                    if (
                    (  categoriaF === "" ||categoriaF === "default" ||
                      categoriaF === null ||
                     cartella.Categoria===categoriaF )&& (clienteF === "" ||clienteF === "default" ||
                     clienteF === null ||  cartella.Cliente===clienteF)
                    ) {
                      return (
                        <option key={n} value={cartella.Nome}>{cartella.Nome}</option>
                      );
                    }else{
                      return null;
                    }
                  })}
              </select>
              <div  style={{marginLeft:'auto'}}>
                <input onChange={(e)=>
                  setFindInput(e.target.value.toLowerCase())
                 }
                  className="input-style2" style={{width:'30rem',height:'1rem',fontWeight:'normal',fontFamily:'Poppins'}} type='text' placeholder={"Cerca documento per: nome, scadenza o manutentore..."}></input>
                </div>            
          <div style={{marginLeft:10,marginRight:5}}>
          <Tooltip style={{width:'fit-content'}} title="Aggiorna lista">
                 <IconButton onClick={()=>reGet()} style={{backgroundColor:'#192bc2',width:'fit-content',height:'fit-content'}}  
                // onClick={()=>getEle(sedi,email)}
                 >
                 <LoopIcon style={{verticalAlign: 'middle', color: 'white',fontSize:'1rem'}}/>
                 </IconButton>
                 </Tooltip>
          </div>
          </div>
          <div style={{marginTop:10,borderRadius:5,width:'75vw',backgroundColor:'#14213d',height:'3rem',marginRight:'auto',display:'flex',alignItems:'center',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
          
          <div style={{borderRight:'1px solid lightgrey',width:'15vw',height:'3rem',alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={() => onSort('NomeFile')}>
            <h4 className="header-table" >Documento  {sortConfig.key === 'NomeFile' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Scadenza')}>
          <h4 className="header-table" >Scadenza  {sortConfig.key === 'Scadenza' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Categoria')}>
          <h4 className="header-table" >Categoria {sortConfig.key === 'Categoria' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Cartella')}>
          <h4 className="header-table" >Cartella {sortConfig.key === 'Cartella' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Cliente')}>
          <h4 className="header-table" >Cliente {sortConfig.key === 'Cliente' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Sede')}>
          <h4 className="header-table" >Sede {sortConfig.key === 'Sede' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
       
          <div style={{width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Size')}>
          <h4 className="header-table" >Dimensione {sortConfig.key === 'Size' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
       
          </div>
          <div style={{overflowY:'auto',height:'74vh'}}>
          {sortedDocs.length>0?sortedDocs.map((ele,n)=>(
            renderDoc(ele)===true? <div key={n} className='table-row'>
            <div  style={{cursor:'pointer',borderRight:'1px solid lightgrey',width:'15vw',height:'6rem',display:'flex',alignItems:'center'}}>
              <div style={{width:'5vw',height:'6rem',display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <img src={ele.Extension==='pdf'?pdf_img:media_img} alt='doc_image' style={{width:'2vw',height:'2vw',marginLeft:'1.5vw',}}></img>
              </div>
              
              <div>
              <Tooltip title={ele.NomeFile}>

                                      <h4 onClick={()=>downloadFile(ele.URL)} className="medium-text" style={{whiteSpace: 'nowrap',color:'#192bc2',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'9vw'}}>
                {ele.NomeFile}
              </h4>
              </Tooltip>
                </div>
              
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw',color:ele.Scadenza!=='No'?'#1B9C85':'black'}} >
                {ele.Scadenza==='No'?'Nessuna':formatData(ele.Scadenza)}
              </h4>
           
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Categoria!==''?ele.Categoria:'Non inserita'}
              </h4>
           
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
                <Tooltip title={ele.Cartella}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Cartella}
              </h4>
              </Tooltip>
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {returnNomeAzienda(ele.Autore)}
              </h4>
           
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.SedeName?ele.SedeName:ele.Sede}
              </h4>
           
              </div>
            </div>
           
            <div style={{width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Size?ele.Size+'MB':'Non inserita'}
              </h4>
           
              </div>
            </div>
           
            <div style={{width:'7vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{marginLeft:'auto',marginRight:'20px',width:'fit-content',}}>
            <h4 onClick={()=>downloadFile(ele.URL)}  style={{borderBottom:'1px solid #192bc2',color:'#192bc2',cursor:'pointer',fontSize:'0.8rem',width:'fit-content',marginLeft:'auto'}} >
                Visualizza
              </h4>
           {user!==null?   <div style={{display:'flex'}}>
                {checkDeleteS(user.Permissions)===true?<Tooltip title="Elimina documento">
                                         <IconButton onClick={()=>deleteDocuments(ele.NomeFile,ele.Sede,ele.StorageRef,n,ele.Id,ele.Categoria,ele.Cartella,ele)}
                                         className="btnD"
                                         style={{cursor:'pointer',marginLeft:0}}
                                       >
                                         <DeleteIcon style={{ color: "#ef476f" }} />
                                       </IconButton>
                                       </Tooltip>:null}
                                       <br/>
                                     
                                       {
                                      ele.Scadenza!=='undefined'&&ele.Scadenza!=="No"?checkWriteS(user.Permissions)===true?<Tooltip title="Modifica scadenza">
                                      <button
                                      onClick={()=>history.push(`/documentimanutentore/${ele.Id}/modificascadenza`)}
                                        className="btnD"
                                        style={{cursor:'pointer',marginLeft:0}}
                                      >
                                        <EditCalendarIcon style={{ color: "#fb8500" }} />
                                      </button>
                                      </Tooltip>:null:
                                      checkWriteS(user.Permissions)===true?<Tooltip title="Pianifica scadenza">
                                       <button
                                       onClick={()=>history.push(`/documentimanutentore/${ele.Id}/aggiungiscadenza`)}
                                         className="btnD"
                                         style={{cursor:'pointer',marginLeft:0}}   
                                       >
                                         <AddAlarmIcon style={{ color: "#fb8500" }} />
                                       </button>
                                       </Tooltip>:null
                                         }
                                         <br/>
                                         {
                                      ele.Scadenza!=='undefined'&&ele.Scadenza!=="No"?checkDeleteS(user.Permissions)===true?<Tooltip title="Rimuovi scadenza">
                                      <button
                                        onClick={()=>deleteExpiration(ele.NomeFile,ele.Id,ele.Sede,ele.Act_ID,ele)}
                                        className="btnD"
                                        style={{cursor:'pointer',marginLeft:0}}
                                      
                                      >
                                        <EventBusyIcon style={{ color: "#ef476f" }} />
                                      </button>
                                      </Tooltip>:null:null
                                         }
                                       </div>:null}
           
              </div>
            </div>
  
            </div>:null
           
          )): <h4 className='normal-text' style={{marginTop:'1rem'}}>Nessun documento inserito.</h4>}
        </div>
        </div>
    </div>
  )
}

export default DocumentiOffritore