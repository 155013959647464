//popup sottomanutenzioni
import React, { useState, useCallback, useEffect } from "react";
import "./Popup.css";
import firebase from "firebase/app";
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@mui/material/Tooltip';
import Commenti from './Commenti';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import EvadiManutenzione from "./EvadiManutenzione";
import GenerazionePdf from "../GenerazionePdf";
import { checkWriteS } from "../EngineClienti/engineClienti";
function SubManutenzioni(props) {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const [activity,setActivity] = useState([]) //lista sottomanutenzioni
  const [evAtt, setEvAtt] = useState(); //popup evadi 
  const [attInfo, setAttInfo] = useState(); //info per att
  const [refC,setRefC] = useState() //ref commenti
  const [sedeC,setSedeC] = useState("") //sede commenti
  const [userC,setUserC] = useState("") //utente commenti
  const [usernameC, setUsernameC] = useState("") //username commenti
  const [titleC,setTitleC] = useState("") //titolo commenti
  const [triggerC, setTriggerC] = useState(false) //trigger commenti
  const [sottRef,setSottRef] = useState('') //sottoman riferimento

  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        if(props.tipo==='Cliente'){
          getActs(props.email)
        }else{
          getActsOff(props.email)
        }
        
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    })},[auth]); //chiama getacts

    function getActsOff(email){
      console.log(email)
      var actx = [] //att normali
      var acts2 = [] //pianificate
      setActivity([])
      db.collection("Users") // Prendo le sedi del master account
      .doc(email)
      .collection("Clienti")
      .doc(props.info.Cliente)
      .collection("Manutenzioni")
      .doc(props.info.Id)
      .collection('Manutenzioni')
      .get().then((querySnapshot)=>{
        querySnapshot.docChanges().forEach((actz)=>{
            db.collection('Manutenzioni').doc(actz.doc.data().ref).get().then((act)=>{

              setActivity((prev)=>{ return [...prev,{
                key: act.id,
                Titolo: act.data().Titolo,
                  Sede: act.data().Sede,
                  Descrizione: act.data().Descrizione,
                  Nome: act.data().Nome,
                  Cognome: act.data().Cognome,
                  Priorità: act.data().Priorità,
                  Oggetto: act.data().Oggetto,
                  Stato: act.data().Stato,
                  Cliente: act.data().Cliente,
                  Sede_cli: act.data().Sede,
                  Elementi: act.data().Elementi,
                  Report: act.data().Report,
                  Esito: act.data().Esito,
                  SottoAttività: act.data().SottoAttività?act.data().SottoAttività:false,
                  Indirizzo: act.data().Indirizzo,
                  Nome_AziendaCli: act.data().Nome_AziendaCli,
                  Nome_AziendaOff: act.data().Nome_AziendaOff,

                  InterventiPath: act.data().InterventiPath,
                  Categoria: act.data().Categoria,
                  Macrocategoria: act.data().Macrocategoria,
                  Operatore: props.getInfo.Tipo==='Lavoratore'||props.getInfo.TipoSott==='Lavoratore'?( act.data().Operatore ? act.data().Operatore:props.getInfo.Tipo==='SottoAccount'?props.getInfo.MasterAccount:auth.currentUser.email):( act.data().Operatore ? act.data().Operatore: props.getInfo.Fornitore),
                  Archiviato: act.data().Archiviato ? act.data().Archiviato : false,
                  Type:act.data().Type?act.data().Type:null
            }
            ]
            })
            })
        })
      })
    
    } //prende sottoman per manutentori
    function getActs(email){
      console.log(email)
      var actx = [] //att normali
      var acts2 = [] //pianificate
      setActivity([])
      console.log(props.info.key,props.sede)
      db.collection("Users") // Prendo le sedi del master account
      .doc(email)
      .collection("Sedi")
      .doc(props.sede)
      .collection("Manutenzioni")
      .doc(props.info.Id)
      .collection('Manutenzioni')
      .get().then((querySnapshot)=>{
        querySnapshot.docChanges().forEach((actz)=>{
            db.collection('Manutenzioni').doc(actz.doc.data().ref).get().then((act)=>{

              setActivity((prev)=>{ return [...prev,{
                key: act.id,
                Titolo: act.data().Titolo,
                  Sede: act.data().Sede,
                  Descrizione: act.data().Descrizione,
                  Nome: act.data().Nome,
                  Cognome: act.data().Cognome,
                  Priorità: act.data().Priorità,
                  Oggetto: act.data().Oggetto,
                  Stato: act.data().Stato,
                  Cliente: act.data().Cliente,
                  Sede_cli: act.data().Sede,
                  Elementi: act.data().Elementi,
                  Report: act.data().Report,
                  Esito: act.data().Esito,
                  SottoAttività: act.data().SottoAttività?act.data().SottoAttività:false,
                  Indirizzo: act.data().Indirizzo,
                  Nome_AziendaCli: act.data().Nome_AziendaCli,
                  Nome_AziendaOff: act.data().Nome_AziendaOff,

                  InterventiPath: act.data().InterventiPath,
                  Categoria: act.data().Categoria,
                  Macrocategoria: act.data().Macrocategoria,
                  Operatore: props.getInfo.Tipo==='Lavoratore'||props.getInfo.TipoSott==='Lavoratore'?( act.data().Operatore ? act.data().Operatore:props.getInfo.Tipo==='SottoAccount'?props.getInfo.MasterAccount:auth.currentUser.email):( act.data().Operatore ? act.data().Operatore: props.getInfo.Fornitore),
                  Archiviato: act.data().Archiviato ? act.data().Archiviato : false,
                  Type:act.data().Type?act.data().Type:null
            }
            ]
            })

            
            })
        })
      })
    
    } //prende sottoman per clienti
    function popEvadi(info) { //apre il popup per evadere
      setAttInfo(info)

      setEvAtt(true)

    } //trigger popup evadi
    function openC(id,sede,user,username,operatore,title,sott){
      console.log(sott,id)
      if(props.tipo==='Lavoratore' ){
        setUsernameC(operatore)
  
      }else{
        setUsernameC(username)
  
      }
      setUserC(user)
      setRefC(id)
      setTitleC(title)
      setSedeC(sede)
      setSottRef(sott)
      setTriggerC(true)
    } //apre commenti NON IN USO

    function generaPdf(manutenzione){
      db.collection("Users").doc(manutenzione.Operatore).get().then((operatoreDoc)=>{
        var operatoreData = operatoreDoc.data()
        var operatore = {Nome_Azienda:operatoreData.Nome_Azienda,Nome:operatoreData.Nome,Cognome:operatoreData.Cognome,Indirizzo:operatoreData.Indirizzo,Sede:props.sede,Telefono:operatoreData.Numero}
  
      var listaElementi = [];
  
      var myManutenzione = manutenzione;
      
      db.collection("Users").doc(myManutenzione.Cliente).get().then((clienteData)=>{
        myManutenzione.Numero_telefono_cli = clienteData.data().Numero;
        if(manutenzione.Type==='doc'){
          var toGet = manutenzione.Titolo.split(' ').slice(1).join(' ')

          manutenzione.Oggetto= toGet
          GenerazionePdf.generaPdfManutenzione(manutenzione,operatore,listaElementi)
        }else{
      manutenzione.Elementi.forEach((elePath,index)=>{
        if(manutenzione.Elementi[0].Elemento !== undefined){ // (se l'elemento ha esito:true o positivo la lista degli elementi è un oggetto, altrimenti è un array di stringhe)
          db.doc(elePath.Elemento).get().then((eleData)=>{
            listaElementi.push({Nome:eleData.data().Nome,
              Esito:elePath.Esito})
            if(index===manutenzione.Elementi.length-1){    GenerazionePdf.generaPdfManutenzione(manutenzione,operatore,listaElementi)
            }
          })
        }else{
        db.doc(elePath).get().then((eleData)=>{
          listaElementi.push({Nome:eleData.data().Nome,
            Esito:manutenzione.Esito})
          if(index===manutenzione.Elementi.length-1){    GenerazionePdf.generaPdfManutenzione(manutenzione,operatore,listaElementi)
          }
        })
      }
      })
    }
      })
      })


      /*db.collection("Users").doc(activity.Operatore).get().then((operatoreDoc)=>{
        var operatoreData = operatoreDoc.data()
        var operatore = {Nome_Azienda:operatoreData.Nome_Azienda,Nome:operatoreData.Nome,Cognome:operatoreData.Cognome,Indirizzo:operatoreData.Indirizzo,Sede:props.sede,Telefono:operatoreData.Numero}
        GenerazionePdf.generaPdfManutenzione(activity,operatore,[{Nome:activity.Oggetto,Esito:activity.Esito}]);
        
      })*/
    } //genera pdf

  return props.trigger ? (
    <div className="pop-opac">  
    <div className="popup" style={{backgroundColor:"white", minHeight:'80vh', height:'fit-content', width:'72vw', left:'18vw'}} >
      {evAtt===true&&<EvadiManutenzione
       info={props.getInfo}
       data={attInfo}
       sede={props.sede}
       cliente={attInfo.Cliente}
       trigger={evAtt}
       setTrigger={setEvAtt}
       getAtt={getActsOff}
       userSede={props.sede}
       sede_cli={attInfo.Sede_cli}
       email={props.getInfo.Email}
       sottoRef={props.info.Id}
       main={props.info}
       setScad={props.setActivity}
                        />}
    <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTriggerPop(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
        <h2  className="page-title" style={{marginLeft:'2rem',marginTop:'0.5rem'}}>SCADENZE CORRETTIVE</h2>
        <div style={{display:'flex',alignItems:'center'}}>
            <h2 style={{marginLeft:'2rem',}}  className="sub-text">Scadenza selezionata: </h2>
             <h2  style={{marginLeft:'0.5rem'}}  className="normal-text">{props.info.Titolo}</h2>
            
            </div>
            <div style={{marginLeft:'2rem',display:'flex',alignItems:'center'}}>
            <h2 className="sub-text">Sede: </h2>
             <h2  style={{marginLeft:'0.5rem'}}  className="normal-text">{props.sede} {props.info.Indirizzo?'- '+props.info.Indirizzo:null}</h2>
            <h2   className="sub-text" style={{marginLeft:'3rem'}}>Cliente: </h2>
             <h2   style={{marginLeft:'0.5rem'}}  className="normal-text">{props.tipo==='Lavoratore'?(
            props.info.Nome_AziendaCli + ' ( ' + props.info.Cliente + ' )'
           ):( 
           props.info.Operatore?props.info.Operatore:null
            )}</h2>
            </div>
            <div style={{height:'55vh',width:'70vw',marginTop:'1vh',marginLeft:'2vw',overflowY:'auto'}}>
            <div  style={{display:'flex',alignItems:'center'}}>
                  <h2 style={{ fontSize: "1.5vw",
              color: "#5C6672",
              border:'1px solid lightgrey',
              borderRight:'none',
              fontWeight: "bold",
              fontFamily: "Poppins",
              marginBottom: 0,
              marginTop: "1vh",
          
              padding:5,
              textAlign:'center',
              width: "15vw",}}>TITOLO</h2>
               <h2 style={{ fontSize: "1.5vw",
              color: "#5C6672", border:'1px solid lightgrey',
              borderRight:'none',
              fontWeight: "normal",
              fontFamily: "Poppins",
              marginBottom: 0,
              marginTop: "1vh",
              textAlign:'center',
              maxWidth:'17vw',              padding:5,

              width:'17vw',
              }}>DESCRIZIONE</h2>
       
                <h2 style={{ fontSize: "1.5vw",
              color: "#5C6672", border:'1px solid lightgrey',
              borderRight:'none',
              fontWeight: "normal",
              fontFamily: "Poppins",
               padding:5,
            
              marginBottom: 0,
              marginTop: "1vh",
              width: "10vw",              textAlign:'center',
            }}>STATO</h2>
               <h2 style={{ fontSize: "1.5vw",
              color: "#5C6672", border:'1px solid lightgrey',
            
              fontWeight: "normal",
              fontFamily: "Poppins",
               padding:5,

              marginBottom: 0,
              marginTop: "1vh",
                textAlign:'center',

              width: "8vw",}}>AZIONI</h2>
                </div>
                {}
              {activity.length>0?activity.map((act,index)=>(
                <div key={index} style={{display:'flex',alignItems:'center',padding:1,}}>
                  <h2 
                  onClick={()=>generaPdf(act)}
                  style={{ fontSize: "1.3vw",
              color: "#118ab2",
              fontWeight: "bold",
              fontFamily: "Poppins",
              marginBottom: 0,
              marginTop: "1vh",padding:5,
              textAlign:'center',
              width: "15vw",maxWidth:'15vw'}}>{act.Titolo}</h2>
               <h2 style={{ fontSize: "1vw",
              color: "black",
              fontWeight: "normal",
              fontFamily: "Poppins",
              marginBottom: 0,
              marginTop: "1vh",
              maxWidth:'17vw',padding:5,
              width:'17vw',textAlign:'center',
              }}>{act.Descrizione} </h2>

                <h2 style={{ fontSize: "1vw",
              color: "black",
              fontWeight: "normal",
              fontFamily: "Poppins",
              padding:5,
              marginBottom: 0,textAlign:'center',
              marginTop: "1vh",
              width: "10vw",}}>{act.Stato}</h2>
                            {checkWriteS(props.getInfo.Permissions)===true?<div style={{width:'10vw'}}>
                            {act.Stato==='In attesa'&&(props.tipo==='Lavoratore'||props.tipo==='Lavoratore')?<label onClick={(e) => {
    
                        }} style={{fontSize:'0.6vw', color:'#ef476f', cursor: 'pointer', fontWeight:'bold',borderBottom:'0.5px solid #ef476f',fontFamily:'Poppins'}}>Pianifica</label>
                            :act.Stato==='Pianificata'&&(props.tipo==='Lavoratore'||props.tipo==='Lavoratore')?<h2 onClick={(e) => {
                              popEvadi(act);
        
                            }} style={{fontSize:'0.8vw', color:'#fb8500', cursor: 'pointer', fontWeight:'bold',borderBottom:'0.5px solid #fb8500',fontFamily:'Poppins',marginLeft:'auto',width:'fit-content',marginRight:'auto',marginTop:0,marginBottom:0,padding:5}}>Evadi</h2>
                            :null}
                            <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',}}>
                            
                            {act.Allegati!==undefined&&<Tooltip title="Allegati" style={{width:'fit-content'}}>
                              <IconButton onClick={()=>{}}>
                                <FilePresentIcon/>
                              </IconButton>
                                </Tooltip>}

                            </div>
                           
                            </div>:null}
                </div>
              )):<h2 style={{color:'black'}}>Nessuna manutenzione</h2>}
            </div>
    </div>
    </div>
  ) : (
    ""
  );
}

export default SubManutenzioni;
