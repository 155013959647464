import React,{useEffect, useState} from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import firebase from "firebase";
import { getDocumenti, getUtente,formatData,checkDeleteS,checkWriteS,getSedeName, getSede,isShared } from '../EngineClienti/engineClienti';
import LoopIcon from '@material-ui/icons/Loop';
import '../general.css'
import pdf_img from '../images/doc-icon.png'
import media_img from '../images/media-icon.png'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteIcon from "@material-ui/icons/Delete";
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import AlarmIcon from '@mui/icons-material/Alarm';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import Condividi from '../Popups/Condividi';
import NavBar from '../NavBar/NavBar'
import Notifiche from '../Notifiche/Notifiche'

const Documenti = ({history}) => {

    const [sedi,setSedi] = useState([]) //lista sedi
    const [categorie,setCategorie] = useState([]) //lista categorie
    const [cartelle,setCartelle] = useState([]) //lista cartelle
    const [documenti, setDocumenti] = useState([]) //lista documenti
    const [user,setUser] = useState(null) //info utente 

    const [triggerCond,setTriggerCond] = useState(false)


    ///////FILTRI///////

    const [sedeF,setSedeF] = useState('default')
    const [categoriaF,setCategoriaF] = useState('default')
    const [cartellaF,setCartellaF] = useState('default')
    const [findInput,setFindInput] = useState('')
    const [hasExp,setHasExp] = useState(0) 


    const db = firebase.firestore()
    const auth = firebase.auth()
    const storage = firebase.storage();
    var storageRef = storage.ref();

    const listaD = JSON.parse(sessionStorage.getItem('documenti'));
    const listaC = JSON.parse(sessionStorage.getItem('categorie'));
    const listaCa = JSON.parse(sessionStorage.getItem('cartelle'));
    const listaSe = JSON.parse(sessionStorage.getItem('sedi'));

          //sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    useEffect(() => {
      if(user!==null){
      if(user.Current_Doc===documenti.length){
        sessionStorage.setItem('documenti', JSON.stringify(documenti));
        sessionStorage.setItem('categorie', JSON.stringify(categorie));
        sessionStorage.setItem('cartelle', JSON.stringify(cartelle));
        sessionStorage.setItem('sedi', JSON.stringify(sedi));
  
      }
    }  
    }, [documenti]);

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      useEffect(()=>{
        if(user!==null){
     
          if(listaD!==null&&listaC!==null&&listaCa!==null){
    
          if(listaD.length>0&&listaC.length>0&&listaCa.length>0&&listaSe.length>0){
            console.log('prendo')
            setCategorie(listaC)
            setDocumenti(listaD)
            setCartelle(listaCa)
            setSedi(listaSe)
          }else{
            getDocumenti(db,user.Email,setSedi,setCategorie,setCartelle,setDocumenti)
    
          }
        }else{
          getDocumenti(db,user.Email,setSedi,setCategorie,setCartelle,setDocumenti)
    
        }
      }
    
      },[user])

      const Buttons = () =>{

        return(
          <div style={{display:'flex',alignItems:'center',gap:'1.5rem'}}>
             <div>
            <h4 onClick={()=>history.push('/documenti/aggiungicategoria')}  className='text-buttons' >
                    AGGIUNGI CATEGORIA
                  </h4>
                  
            </div>
            <div>
            <h4 onClick={()=>history.push('/documenti/aggiungidocumento')}  className='text-buttons' >
                    AGGIUNGI DOCUMENTO
                  </h4>
                  
            </div>
           
            <div>
            <h4 onClick={()=>setTriggerCond(true)}  className='text-buttons'  >
                    CONDIVISIONE MULTIPLA
                  </h4>
                  
            </div>
          
          </div>
        )
      }

      function renderDoc(doc){
        var nome = doc.NomeFile?doc.NomeFile.toString().toLowerCase():''
        var scadenza = doc.Scadenza?doc.Scadenza.toString().toLowerCase():''
        var shared = doc.Shared?doc.Shared.toString().toLowerCase():''
    
        if((sedeF === "" ||sedeF === "default" || sedeF === null || doc.Sede === sedeF) &&
        (categoriaF === "" ||categoriaF === "default" ||
          categoriaF === null ||
          doc.Categoria === categoriaF) &&
          (hasExp===0||(hasExp===1&&doc.Scadenza!=='No')||(hasExp===2&&doc.Scadenza==='No'))
          &&
        (cartellaF === "" ||cartellaF === "default" ||
          cartellaF === null ||
          doc.Cartella===cartellaF)&&(findInput===''||findInput===null||nome.includes(findInput)||scadenza.includes(findInput)||shared.includes(findInput))){
            return true
          }else{
            return false
          }
      }
      function reGet (){
        sessionStorage.clear()
        getDocumenti(db,user.Email,setSedi,setCategorie,setCartelle,setDocumenti)
    
      }
      function downloadFile(url) {
        //funzione per aprire il documento
        window.open(url, "_blank");
      } //funzione che apre i file
      function deleteDocuments(name, folder,storageref,index,key,cat,cart) {
        //funzione per eliminare il documento
        if(window.confirm("Vuoi eliminare il documento "+name+" ?")){
          db.collection("Users")
              .doc(user.Email).get().then((userx)=>{
                db
              .collection("Documenti")
              .doc(key).get().then((docx)=>{
    
                db.collection("Users")
                .doc(user.Email).update({
                  Current_MB: parseFloat(userx.data().Current_MB)-parseFloat(docx.data().Size),
                  Current_Doc: firebase.firestore.FieldValue.increment(-1)
    
                }).then(()=>{
                  
                      var deleteRef;
                      if(storageref === undefined){
                        deleteRef = storageRef.child(
                            user.Email + "/" + folder + "/" + cart + "/" + key
                      );
                      }
                      else{
                        deleteRef = storageRef.child(storageref);
                      }
                   
                      deleteRef.delete().then(()=>{
                      
    
                      db.collection("Users")
                      .doc(user.Email)
                      .collection("Sedi")
                      .doc(folder)
                      .collection("Categorie")
                      .doc(cat)
                      .collection("Cartelle")
                      .doc(cart)
                      .collection("Documenti")
                      .doc(key)
                      .delete()
                      .then(() => {
    
                        db.collection("Users")
                      .doc(user.Email)
                      .collection("Sedi")
                      .doc(folder).update({
                        Doc:firebase.firestore.FieldValue.increment(-1)
                      })
    
                      db.collection("Users")
                      .doc(user.Email)
                      .collection("Sedi")
                      .doc(folder)
                      .collection("Categorie")
                      .doc(cat)
                      .collection("Cartelle")
                      .doc(cart).update({
                        Doc:firebase.firestore.FieldValue.increment(-1)
                      })
    
    
                      db.collection("Users")
                      .doc(user.Email)
                      .collection("Sedi")
                      .doc(folder)
                      .collection("Categorie")
                      .doc(cat).update({
                        Doc:firebase.firestore.FieldValue.increment(-1)
                      })
    
    
                        db.collection("Documenti").doc(key).delete()


                        if(docx.data().Act_ID!==null){
 // Elimino man
 db.collection("Users").doc(user.Email)
 .collection("Sedi").doc(folder)
 .collection("Manutenzioni").doc(docx.data().Act_ID)
 .delete()
 // Elimino annotazione
 db.collection("Users").doc(user.Email)
 .collection("Annotazioni").doc(docx.data().Act_ID)
 .delete()
 if(docx.data().Shared){
 db.collection("Users").doc(docx.data().Shared)
 .collection("Clienti").doc(user.Email)
 .collection("Manutenzioni").doc(docx.data().Act_ID)
 .delete()
 }
 db.collection('Manutenzioni').doc(docx.data().Act_ID).delete()

                        }
    
                       
                          //elimina su storage
                          const arr = [...documenti];
                  arr.splice(index,1)
                  setDocumenti(arr)
                  sessionStorage.setItem('documenti', JSON.stringify(arr));

                          alert("File eliminato con successo");
                            
                       
              
                      })
                      .catch((err) => {
                        alert("Errore: " + err);
                      });
                    })
                })
              })
               
              })
      
             
        
      }
      } //funzione per eliminare i documenti
      function deleteExpiration(nomefile,keyy,sede,act_id) {
        console.log(nomefile,keyy,sede,act_id)
        if(window.confirm("Vuoi eliminare la scadenza del documento "+nomefile+" ?")){
          db.collection('Users').doc(user.Email).collection('Sedi').doc(sede).collection('Manutenzioni').doc(act_id).delete().then(()=>{ //elimina att da cartella in utente
            db.collection('Manutenzioni').doc(act_id).delete().then(()=>{ //elimina att da cartella generale
              db.collection('Users').doc(user.Email).collection('Annotazioni').doc(act_id).delete().then(()=>{ //elimina annotazione
                db.collection('Documenti').doc(keyy).update({
                  Scadenza:"No",
                  Act_ID: ''
                }).then(()=>{
                  var arr = [...documenti]
                  var indD = arr.findIndex((e)=>e.Id===keyy)
                  arr[indD].Scadenza = 'No'
                  sessionStorage.setItem('documenti', JSON.stringify(arr));

            
                setDocumenti(arr)

                  alert('Scadenza rimossa')
                })
              })
            })
          })
        } 
      } //funzione per eliminare la scadernza

      const onSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction });
      };
    
      const sortedDocs = React.useMemo(() => {
        let sortableItems = [...documenti];
        if (sortConfig.key !== null) {
          sortableItems.sort((a, b) => {
            if (typeof a[sortConfig.key] === 'string') {
              return a[sortConfig.key].localeCompare(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            } else if (sortConfig.key === 'date') {
              return new Date(a[sortConfig.key]) - new Date(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            }else if (sortConfig.key === 'number') {
              return (a[sortConfig.key] - b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            } else {
              return 0;
            }
          });
        }
        return sortableItems;
      }, [documenti, sortConfig]);

  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
      {user!==null&&user!==null?<Notifiche user={user}/>:null}
            <NavBar/>
{triggerCond===true?<Condividi page='doc' email={user.Email}
 docs={documenti} setDocs={setDocumenti} type='cli'  trigger={triggerCond} setTrigger={setTriggerCond}/>:null}
        <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
        <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>DOCUMENTI</h4>
     
      </div>
       {checkWriteS(user?user.Permissions:null)===true? <Buttons/>:null}
        <div className='box-shadow' style={{marginTop:'0.5rem',borderRadius:5,width:'75vw',backgroundColor:'white',height:'3rem',display:'flex',alignItems:'center'}}>
          <div style={{width:'7vw',height:'3rem',borderRight:'1px solid lightgrey',}}>
          <h4 className="normal-text" style={{marginLeft:'auto',marginRight:'auto',marginTop:'0.3rem',fontSize:'1rem',width:'fit-content'}}>
           {documenti.length}
          </h4>
          <h4 className="sub-text" style={{marginLeft:'auto',marginRight:'auto',fontSize:'0.8rem',width:'fit-content'}}>
          {'Documenti totali'}
          </h4>
          </div>
          <Tooltip title={hasExp===0?'Con e senza scadenza':hasExp===1?'Con scadenza':'Senza scadenza'}>
          <IconButton onClick={()=>hasExp===0?setHasExp(1):hasExp===1?setHasExp(2):setHasExp(0)} style={{width:'fit-content',marginLeft:5}}>
          <CalendarMonthIcon style={{color:hasExp===0?'grey':hasExp===1?'green':'red'}}/>
          </IconButton>
          </Tooltip>
          <select
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw'}}
                defaultValue={"default"}
                value={sedeF}
                className='input-style'
                onChange={(e) => {
                  setSedeF(e.target.value);
                  setCategoriaF('default');
                 setCartellaF('default');
                }}
              >
                <option value={"default"} disabled>
                  Seleziona sede...
                </option>
                <option value={""}>Tutte</option>
                {sedi && sedi.length > 0 && sedi.map((sede,n)=>{
                                  return <option key={n} value={sede.Id}>{sede.Nome?sede.Nome!==''?sede.Nome:sede.Comune:sede.Comune}</option>
                              })}
              </select>
             {/* */}
              <select
                className='input-style'
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw'}}
                defaultValue={"default"}
                value={categoriaF}
                onChange={(e) => {
                  setCategoriaF(e.target.value);
                  setCartellaF('default');
                }}
                name="categoria"
              >
                <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
                <option value={""}>Tutte</option>
                {categorie&&
                categorie.map((categoria,n) => {
                    if (
                      sedeF === "" || sedeF === "default" ||
                      sedeF === null ||
                      categoria.Sede===sedeF
                    ) {
                      return (
                        <option key={n} value={categoria.Id}>
                          {categoria.Nome}
                        </option>
                      );
                    }else{
                      return null
                    }
                  })}
              </select>
             {/* */}
              <select
                className='input-style'
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw',width:'fit-content',maxWidth:'10rem'}}
                defaultValue={"default"}
                value={cartellaF}
                onChange={(e) =>
                   setCartellaF(e.target.value)
                }
                name="cartella"
              >
                <option value={"default"} disabled>
                  {'Seleziona sottocategoria...'}
                </option>
                <option value={""}>Tutte</option>
                {cartelle&&cartelle.map((cartella,n) => {
                    if (
                    (  categoriaF === "" || categoriaF === "default" ||
                      categoriaF === null ||
                     cartella.Categoria===categoriaF )&& (sedeF === "" ||sedeF === "default" ||
                     sedeF === null ||  cartella.Sede===sedeF)
                    ) {
                      return (
                        <option key={n} value={cartella.Id}>{cartella.Nome}</option>
                      );
                    }else{
                      return null;
                    }
                  })}
              </select>
              <div  style={{marginLeft:'auto'}}>
                <input onChange={(e)=>
                  setFindInput(e.target.value.toLowerCase())
                 }
                  className="input-style2" style={{width:'30rem',height:'1rem',fontWeight:'normal',fontFamily:'Poppins'}} type='text' placeholder={"Cerca documento per: nome, scadenza o manutentore..."}></input>
                </div>            
          <div style={{marginLeft:10,marginRight:5}}>
          <Tooltip style={{width:'fit-content'}} title="Aggiorna lista">
                 <IconButton onClick={()=>reGet()} style={{backgroundColor:'#192bc2',width:'fit-content',height:'fit-content'}}  
                // onClick={()=>getEle(sedi,email)}
                 >
                 <LoopIcon style={{verticalAlign: 'middle', color: 'white',fontSize:'1rem'}}/>
                 </IconButton>
                 </Tooltip>
          </div>
          </div>
          <div style={{marginTop:10,borderRadius:5,width:'75vw',backgroundColor:'#14213d',height:'3rem',marginRight:'auto',display:'flex',alignItems:'center',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
          
          <div style={{borderRight:'1px solid lightgrey',width:'15vw',height:'3rem',alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={() => onSort('NomeFile')}>
            <h4 className="header-table" >Documento  {sortConfig.key === 'NomeFile' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Categoria')}>
          <h4 className="header-table" >Categoria  {sortConfig.key === 'Categoria' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Cartella')}>
          <h4 className="header-table" >Cartella {sortConfig.key === 'Cartella' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Sede')}>
          <h4 className="header-table" >Sede {sortConfig.key === 'Sede' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Scadenza')}>
          <h4 className="header-table" >Scadenza {sortConfig.key === 'Scadenza' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Size')}>
          <h4 className="header-table" >Dimensione {sortConfig.key === 'Size' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
       
          <div style={{width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('SharedN')}>
          <h4 className="header-table" >Condiviso con {sortConfig.key === 'SharedN' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>
          </div>
       
          </div>
          <div style={{overflowY:'auto',height:'74vh'}}>
          {sortedDocs.length>0?sortedDocs.map((ele,n)=>(
            renderDoc(ele)===true? <div key={n} className='table-row'>
            <div  style={{cursor:'pointer',borderRight:'1px solid lightgrey',width:'15vw',height:'6rem',display:'flex',alignItems:'center'}}>
              <div style={{width:'5vw',height:'6rem',display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <img src={ele.Extension==='pdf'?pdf_img:media_img} alt='doc_image' style={{width:'2vw',height:'2vw',marginLeft:'1.5vw',}}></img>
              </div>
              
              <div>
              <Tooltip title={ele.NomeFile}>

                                      <h4 onClick={()=>downloadFile(ele.URL)} className="medium-text" style={{whiteSpace: 'nowrap',color:'#192bc2',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'9vw'}}>
                {ele.NomeFile}
              </h4>
              </Tooltip>
                </div>
              
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Categoria!==''?ele.Categoria:'Non inserita'}
              </h4>
           
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
                <Tooltip title={ele.Cartella}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Cartella}
              </h4>
              </Tooltip>
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {getSedeName(ele.Sede,sedi)}
              </h4>
           
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw',color:ele.Scadenza!=='No'?'#1B9C85':'black'}} >
                {ele.Scadenza==='No'?'Nessuna':formatData(ele.Scadenza)}
              </h4>
           
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Size?ele.Size+'MB':'Non inserita'}
              </h4>
           
              </div>
            </div>
            <div style={{width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Shared===''?'Nessuno':ele.SharedN}
              </h4>
           
              </div>
            </div>
            <div style={{width:'7vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{marginLeft:'auto',marginRight:'20px',width:'fit-content',}}>
            <h4 onClick={()=>downloadFile(ele.URL)}  style={{borderBottom:'1px solid #192bc2',color:'#192bc2',cursor:'pointer',fontSize:'0.8rem',width:'fit-content',marginLeft:'auto'}} >
                Visualizza
              </h4>
              <div style={{display:'flex'}}>
               {checkDeleteS(user?user.Permissions:null)===true?isShared(ele)===false? <Tooltip title="Elimina documento">
                                         <IconButton onClick={()=>deleteDocuments(ele.NomeFile,ele.Sede,ele.StorageRef,n,ele.Id,ele.Categoria,ele.Cartella)}
                                         className="btnD"
                                         style={{cursor:'pointer',marginLeft:0}}
                                       >
                                         <DeleteIcon style={{ color: "#ef476f" }} />
                                       </IconButton>
                                       </Tooltip>:null:null}
                                       <br/>
                                     
                                       {checkWriteS(user?user.Permissions:null)===true?isShared(ele)===false?
                                      ele.Scadenza!=='undefined'&&ele.Scadenza!=="No"?<Tooltip title="Modifica scadenza">
                                      <button
                                      onClick={()=>history.push(`/documenti/${ele.Id}/modificascadenza`)}
                                        className="btnD"
                                        style={{cursor:'pointer',marginLeft:0}}
                                      >
                                        <EditCalendarIcon style={{ color: "#fb8500" }} />
                                      </button>
                                      </Tooltip>:
                                      <Tooltip title="Pianifica scadenza">
                                       <button
                                       onClick={()=>history.push(`/documenti/${ele.Id}/aggiungiscadenza`)}
                                         className="btnD"
                                         style={{cursor:'pointer',marginLeft:0}}   
                                       >
                                         <AddAlarmIcon style={{ color: "#fb8500" }} />
                                       </button>
                                       </Tooltip>
                                         :null:null}
                                         <br/>
                                         {checkDeleteS(user?user.Permissions:null)===true?
                                      ele.Scadenza!=='undefined'&&ele.Scadenza!=="No"?<Tooltip title="Rimuovi scadenza">
                                      <button
                                        onClick={()=>deleteExpiration(ele.NomeFile,ele.Id,ele.Sede,ele.Act_ID)}
                                        className="btnD"
                                        style={{cursor:'pointer',marginLeft:0}}
                                      
                                      >
                                        <EventBusyIcon style={{ color: "#ef476f" }} />
                                      </button>
                                      </Tooltip>:null:null
                                         }
                                       </div>
           
              </div>
            </div>
  
            </div>:null
           
          )): <h4 className='normal-text' style={{marginTop:'1rem'}}>Nessun documento inserito.</h4>}
        </div>
        </div>
    </div>
  )
}

export default Documenti