import React,{useEffect, useState, useMemo} from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { Icon, IconButton, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import firebase from "firebase";
import { getElementi, getUtente,getIcon,checkDeleteS,checkWriteS } from '../EngineClienti/engineClienti';
import LoopIcon from '@material-ui/icons/Loop';
import '../general.css'
import { useHistory } from "react-router-dom";
import Condividi from '../Popups/Condividi';
import Loading from '../loading';
import * as XLSX from "xlsx/xlsx.mjs";
import DeleteIcon from '@mui/icons-material/Delete';
import { getElementiClienti } from '../EngineOffritore/engineOffritore';
import NavBar2 from "../NavBar/NavBar2";
import Notifiche from '../Notifiche/Notifiche';
import GridOnIcon from '@mui/icons-material/GridOn';
import { FixedSizeList as List } from 'react-window';

const ElementiOffritore = () => {



  const [elementi,setElementi] = useState([]) //lista elementi
  const [categorie,setCategorie] = useState([]) //lista categorie
  const [clienti,setClienti] = useState([]) //lista clienti
  const [sottocategorie,setSottoCategorie] = useState([]) //lista sottocategorie
  const [user,setUser] = useState(null) //info utente 


  ////filtri

  const [clienteF,setClienteF] = useState('default')
  const [categoriaF,setCategoriaF] = useState('default')
  const [sottocategoriaF,setSottoCategoriaF] = useState('default')
  const [findInput,setFindInput] = useState('')

  //// trigger popups

  const [triggerCond,setTriggerCond] = useState(false) //condivisione multipla
  const [openExcel,setOpenExcel] = useState(false) //apri menu excel

  const db = firebase.firestore()
  const auth = firebase.auth()
  const history = useHistory()
  const listaE = JSON.parse(sessionStorage.getItem('elementi'));
  const listaC = JSON.parse(sessionStorage.getItem('categorie'));
  const listaS = JSON.parse(sessionStorage.getItem('sottocategorie'));
  const listaCli = JSON.parse(sessionStorage.getItem('clienti'));

  //sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const [isEleBuilder,setIsEleBuilder] = useState(0)



  useEffect(() => {
    if(user!==null){
    if(user.Current_Ele===elementi.length){
      sessionStorage.setItem('elementi', JSON.stringify(elementi));
      sessionStorage.setItem('categorie', JSON.stringify(categorie));
      sessionStorage.setItem('sottocategorie', JSON.stringify(sottocategorie));
      sessionStorage.setItem('clienti', JSON.stringify(clienti));

    }
  }
     
    
  }, [elementi]);


  useEffect(()=>{
    auth.onAuthStateChanged(function (user) {
      if (user) {
        getUtente(db,auth,setUser)
      }
    })
  },[auth])

  useEffect(()=>{
    if(user!==null){
     
      if(listaE!==null&&listaC!==null&&listaS!==null){

      if(listaE.length>0&&listaC.length>0&&listaS.length>0&&listaCli.length>0){
        setCategorie(listaC)
        setElementi(listaE)
        setSottoCategorie(listaS)
        setClienti(listaCli)
      }else{
        getElementiClienti(db,user.Email,setClienti,setElementi,setCategorie,setSottoCategorie)

      }
    }else{
      getElementiClienti(db,user.Email,setClienti,setElementi,setCategorie,setSottoCategorie)

    }
  }

  },[user])

  function renderEle(map){
    var nome = map.Nome?map.Nome.toString().toLowerCase():''
    var marca = map.Marca?map.Marca.toString().toLowerCase():''
    var codice = map.Code?map.Code.toString().toLowerCase():''
    var sede = map.Sede?map.Sede.toString().toLowerCase():''
    var descrizione = map.Descrizione?map.Descrizione.toString().toLowerCase():''

    console.log(map.MyEleFatherName)

    if((clienteF === "" || clienteF === null|| clienteF === 'default' || map.Cliente === clienteF) &&
    (categoriaF === ""|| categoriaF === 'default' ||
      categoriaF === null ||
      map.Categoria === categoriaF) &&
      (isEleBuilder===0||(isEleBuilder===1&&(map.MyEleFatherName!==''&&map.MyEleFatherName!==null&&map.MyEleFatherName!==undefined))||(isEleBuilder===2&&(map.MyEleFatherName===''||map.MyEleFatherName===null||map.MyEleFatherName===undefined)))&&
    (sottocategoriaF === "" ||
      sottocategoriaF === null || sottocategoriaF === 'default' ||
      map.Macrocategoria===sottocategoriaF)&&(findInput===''||findInput===null||nome.includes(findInput)||marca.includes(findInput)||codice.includes(findInput)||sede.includes(findInput)||descrizione.includes(findInput))){
        return true
      }else{
        return false
      }
  }

  const Buttons = () =>{

    return(
      <div style={{display:'flex',alignItems:'center',gap:'1.5rem'}}>
        <div>
        <h4 onClick={()=>history.push('/elementimanutentore/aggiungicategoria')}  className='text-buttons' >
                AGGIUNGI CATEGORIA
              </h4>
        </div>
        <div>
        <h4 onClick={()=>history.push('/elementimanutentore/aggiungisottocategoria')}  className='text-buttons' >
                AGGIUNGI SOTTOCATEGORIA
              </h4>
              
        </div>
        <div>
        <h4 onClick={()=>history.push('/elementimanutentore/aggiungielemento')}  className='text-buttons' >
                AGGIUNGI ELEMENTO
              </h4>
              
        </div>
      
      </div>
    )
  }
  function reGet (){
    sessionStorage.clear()
    getElementiClienti(db,user.Email,setClienti,setElementi,setCategorie,setSottoCategorie)

  }

  function deleteElem(
    ele,
    index
  ) {
    if (window.confirm("Sei sicuro di voler eliminare l'elemento "+ ele.Nome+"?")) {
      var arr = [...elementi];
      arr.splice(index, 1);
  
      db.collection("Users")
        .doc(user.Email)
        .collection("Clienti")
        .doc(ele.Cliente)
        .update({
          Ele: firebase.firestore.FieldValue.increment(-1),
        }) //decrementa il valore nel cliente OFF
        .then((docz) => {
          db.collection("Users")
          .doc(user.Email)
          .collection("Clienti")
        .doc(ele.Cliente)
            .collection("Elementi")
            .doc(ele.Id)
            .delete() //elimina il ref dell'elemento dall'utente OFF
            .then((doc) => {
              db.collection("Users")
              .doc(user.Email)
                .update({
                  Current_Ele: firebase.firestore.FieldValue.increment(-1),
                }) //decrementa il valore ele nell utente OFF
                .then(() => {
                  db.collection("Users")
                  .doc(ele.Cliente)
                  .collection("Sedi")
                  .doc(ele.Sede)
                    .update({
                      Ele: firebase.firestore.FieldValue.increment(-1),
                    }) //decrementa il valore ele nella sede CLI
                    .then(() => {
                      db.collection("Users")
                      .doc(ele.Cliente)
                      .update({
                          Current_Ele:firebase.firestore.FieldValue.increment(-1),
                        }) //decrementa il valore current ele nell'utente CLI
                        .then(() => {

                          db.collection("Users")
                  .doc(ele.Cliente)
                  .collection("Sedi")
                  .doc(ele.Sede)
                  .collection('Categorie')
                  .doc(ele.Categoria).update({
                    Ele:firebase.firestore.FieldValue.increment(-1), 
                  }) //decrementa valore su cat CLI
                  .then(()=>{
                    db.collection("Users")
                    .doc(ele.Cliente)
                    .collection("Sedi")
                    .doc(ele.Sede)
                    .collection('Categorie')
                    .doc(ele.Categoria)
                    .collection('Sottocategorie')
                    .doc(ele.Macrocategoria)
                    .update({
                      Valore:firebase.firestore.FieldValue.increment(-1), 
                    }) //decrementa valore su sottocat CLI
                    .then(()=>{
                      db.collection("Users")
                      .doc(ele.Cliente)
                      .collection("Sedi")
                      .doc(ele.Sede)
                      .collection('Categorie')
                      .doc(ele.Categoria)
                      .collection('Sottocategorie')
                      .doc(ele.Sottocategoria)
                      .collection('Elementi')
                      .doc(ele.Id).delete().then(()=>{
                        db.collection("Elementi")
                        .doc(ele.Id)
                        .delete()
                        .then(() => {
                          //elimina definitivamente l'elemento
                          sessionStorage.setItem('elementi', JSON.stringify(arr));

                          setElementi(arr);
                          alert("Elemento eliminato.");
                        });
                      })
                    })
  
                  })

                          
                        });
                    });
                });
            });
        });
    }
  } //funzione per eliminare gli elementi

  function returnNomeAzienda(name){

    var arr = [...clienti]
    var index = arr.findIndex((e)=>e.Email===name)

    return clienti[index].Nome_Azienda

  }

  const onSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortedElements = React.useMemo(() => {
    let sortableItems = [...elementi];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (typeof a[sortConfig.key] === 'string') {
          return a[sortConfig.key].localeCompare(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        } else if (sortConfig.key === 'date') {
          return new Date(a[sortConfig.key]) - new Date(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
        } else {
          return 0;
        }
      });
    }
    return sortableItems;
  }, [elementi, sortConfig]);


   

    const filteredElements = useMemo(() => 
      sortedElements.filter((ele) => renderEle(ele)), 
      [sortedElements, renderEle]
    );

    const renderFilteredRow = ({ index, style }) => {
      const ele = filteredElements[index];
      return (
        <div style={style} key={ele.Id}>
<div className='table-row'>
               <div  style={{cursor:'pointer',borderRight:'1px solid lightgrey',width:'15vw',height:'6rem',display:'flex',alignItems:'center'}}>
                 <div style={{width:'5vw',height:'6rem',}}>
                 <img src={ele.Photo!==null&&ele.Photo!==undefined?ele.Photo:getIcon(ele)} alt='doc_image' style={{width:'3vw',height:'3vw',marginLeft:'1.5vw',marginTop:'1rem',marginBottom:'auto'}}></img>
                 </div>
                 
                 <div>
                 <Tooltip title={ele.Nome}>
   
                                         <h4 onClick={()=>history.push(`/elementi/elemento/${ele.Id}`)} className="medium-text" style={{whiteSpace: 'nowrap',color:'#192bc2',fontWeight:'bold',fontSize:'0.9rem',
   overflow: 'hidden',
   textOverflow: 'ellipsis',maxWidth:'9vw'}}>
                   {ele.Nome}
                 </h4>
                 </Tooltip>
                   </div>
                 
               </div>
               <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div>
               <Tooltip title={ele.Descrizione!==''?ele.Descrizione:'Non inserita'}>
   
                 <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                   {ele.Descrizione!==''?ele.Descrizione:'Non inserita'}
                 </h4>
              </Tooltip>
              <Tooltip title={'Elemento padre:'+ ele.MyEleFatherName!==''&&ele.MyEleFatherName!==null&&ele.MyEleFatherName!==undefined?ele.MyEleFatherName:'Nessuno'}>
   
   <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
     Elemento padre: {ele.MyEleFatherName!==''&&ele.MyEleFatherName!==null&&ele.MyEleFatherName!==undefined?ele.MyEleFatherName:'Nessuno'}
   </h4>
   
   </Tooltip>
                 </div>
               </div>
               <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                   {ele.Categoria}
                 </h4>
              
                 </div>
               </div>
               <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                   {ele.Macrocategoria}
                 </h4>
              
                 </div>
               </div>
               <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                 {returnNomeAzienda(ele.Cliente)}
   
                 </h4>
              
                 </div>
               </div>
               <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                 {ele.SedeName?ele.SedeName:ele.Sede}
                 </h4>
              
                 </div>
               </div>
               <div style={{width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div>
                 <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                   {ele.Code===''?'Non inserito':ele.Code}
                 </h4>
              
                 </div>
               </div>
               <div style={{width:'7vw',height:'6rem',display:'flex',alignItems:'center'}}>
               <div style={{marginLeft:'auto',marginRight:'20px',width:'fit-content'}}>
                 <h4 onClick={()=>history.push(`/elementi/elemento/${ele.Id}`)} style={{borderBottom:'1px solid #192bc2',color:'#192bc2',cursor:'pointer',fontSize:'0.8rem',}} >
                   Dettagli
                 </h4>
                 {checkDeleteS(user.Permissions)===true?<div style={{width:'fit-content',marginLeft:'auto'}}>
                 <IconButton onClick={()=>deleteElem(ele,index)} style={{width:'fit-content'}} >
                 <DeleteIcon style={{color:'#FF0060'}}/>
                   </IconButton>
                   </div>:null}
                   
              
                 </div>
               </div>
     
               </div>
        </div>
      )}

    const vhToPixels = (vh) => {
      return Math.round(window.innerHeight * (vh / 100));
    };

  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
       {user!==null?<Notifiche user={user}/>:null}
      <NavBar2/>
       {triggerCond===true?<Condividi page='ele' email={user.Email}
 docs={elementi} setDocs={setElementi} type='cli'  trigger={triggerCond} setTrigger={setTriggerCond}/>:null}
   
            <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
              {elementi?<div>
            <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>ELEMENTI</h4>
        
      </div>
      {user!==null?checkWriteS(user.Permissions)?<Buttons/>:null:null}
      <div className='box-shadow' style={{marginTop:'0.5rem',borderRadius:5,width:'75vw',backgroundColor:'white',height:'3rem',display:'flex',alignItems:'center'}}>
          <div style={{width:'7vw',height:'3rem',borderRight:'1px solid lightgrey',}}>
          <h4 className="normal-text" style={{marginLeft:'auto',marginRight:'auto',marginTop:'0.3rem',fontSize:'1rem',width:'fit-content'}}>
           {elementi.length}
          </h4>
          <h4 className="sub-text" style={{marginLeft:'auto',marginRight:'auto',fontSize:'0.8rem',width:'fit-content'}}>
          {'Elementi totali'}
          </h4>
          </div>
          <Tooltip title={isEleBuilder===0?'Inseriti e non in MyEleBuilder':isEleBuilder===1?'Inseriti in MyEleBuilder':'Non inseriti in MyEleBuilder'}>
          <IconButton onClick={()=>isEleBuilder===0?setIsEleBuilder(1):isEleBuilder===1?setIsEleBuilder(2):setIsEleBuilder(0)} style={{width:'fit-content',marginLeft:5}}>
          <GridOnIcon style={{color:isEleBuilder===0?'grey':isEleBuilder===1?'green':'red'}}/>
          </IconButton>
          </Tooltip>
          <select
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw'}}
                defaultValue={"default"}
                value={clienteF}
                className='input-style'
                onChange={(e) => {
                  setClienteF(e.target.value);
                  setCategoriaF('default');
                 setSottoCategoriaF('default');
                }}
              >
                <option value={"default"} disabled>
                  Seleziona cliente...
                </option>
                <option value={""}>Tutti</option>
                {clienti && clienti.length > 0 && clienti.map((sede,n)=>{
                                  return <option key={n} value={sede.Email}>{sede.Nome_Azienda}</option>
                              })}
              </select>
             {/* */}
              <select
                className='input-style'
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw'}}
                defaultValue={"default"}
                value={categoriaF}
                onChange={(e) => {
                  setCategoriaF(e.target.value);
                  setSottoCategoriaF('default');
                }}
                name="categoria"
              >
                <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
                <option value={""}>Tutte</option>
                {categorie&&
                categorie.map((categoria,n) => {
                     if( (  clienteF === "" ||
                      clienteF === "default" ||
                     clienteF === null ||
                    categoria.Cliente===clienteF )){
                      return (
                        <option key={n} value={categoria.Nome}>
                          {categoria.Nome}
                        </option>
                      );
                     }
                      
                    
                  })}
              </select>
             {/* */}
              <select
                className='input-style'
                style={{marginTop:'auto',marginBottom:'auto',marginLeft:'1vw',width:'fit-content',maxWidth:'10rem'}}
                defaultValue={"default"}
                value={sottocategoriaF}
                onChange={(e) =>
                   setSottoCategoriaF(e.target.value)
                }
                name="cartella"
              >
                <option value={"default"} disabled>
                  {'Seleziona sottocategoria...'}
                </option>
                <option value={""}>Tutte</option>
                {sottocategorie&&sottocategorie.map((cartella,n) => {
                    if (
                    (  categoriaF === "" ||
                      categoriaF === "default" ||
                      categoriaF === null ||
                     cartella.Categoria===categoriaF ) && 
                     (  clienteF === "" ||
                      clienteF === "default" ||
                      clienteF === null ||
                     cartella.Cliente===clienteF )
                    ) {
                      return (
                        <option key={n} value={cartella.Nome}>{cartella.Nome}

                        </option>
                      );
                    }else{
                      return null;
                    }
                  })}
              </select>
              <div  style={{marginLeft:'auto'}}>
                <input onChange={(e)=>
                  setFindInput(e.target.value.toLowerCase())
                 }
                  className="input-style2" style={{width:'30rem',height:'1rem',fontWeight:'normal',fontFamily:'Poppins'}} type='text' placeholder={"Cerca elemento per: nome, descrizione, marca o codice..."}></input>
                </div>            
          <div style={{marginLeft:10,marginRight:5}}>
          <Tooltip style={{width:'fit-content'}} title="Aggiorna lista">
                 <IconButton onClick={()=>reGet()} style={{backgroundColor:'#192bc2',width:'fit-content',height:'fit-content'}}  
                // onClick={()=>getEle(sedi,email)}
                 >
                 <LoopIcon style={{verticalAlign: 'middle', color: 'white',fontSize:'1rem'}}/>
                 </IconButton>
                 </Tooltip>
          </div>
          </div>
          {console.log(user)}
      <div style={{marginTop:10,borderRadius:5,width:'75vw',backgroundColor:'#14213d',height:'3rem',marginRight:'auto',display:'flex',alignItems:'center',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
          
          <div style={{borderRight:'1px solid lightgrey',width:'15vw',height:'3rem',alignItems:'center',justifyContent:'center',cursor:'pointer'}}  onClick={() => onSort('Nome')}>
            <h4 className="header-table" >Elemento {sortConfig.key === 'Nome' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Descrizione')}>
          <h4 className="header-table" >Descrizione {sortConfig.key === 'Descrizione' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Categoria')}>
          <h4 className="header-table" >Categoria {sortConfig.key === 'Categoria' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Macrocategoria')}>
          <h4 className="header-table" >Sottocategoria {sortConfig.key === 'Macrocategoria' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Cliente')}>
          <h4 className="header-table" >Cliente {sortConfig.key === 'Cliente' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Sede')}>
          <h4 className="header-table" >Sede {sortConfig.key === 'Sede' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Codice Univoco')}>
          <h4 className="header-table" >Codice univoco {sortConfig.key === 'Codice Univoco' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          </div>
          <div style={{overflowY:'auto',height:'74vh'}}>
          {sortedElements.length>0?(
        <List
          height={vhToPixels(74)} // Altezza della finestra di visualizzazione
          itemCount={filteredElements.length} // Usa il numero di elementi filtrati
          itemSize={100} // Altezza di ogni riga (può essere adattato)
          width={'100%'}
        >
           {renderFilteredRow}
        </List>
      ) : <h4 className='normal-text' style={{marginTop:'1rem'}}>Nessun elemento inserito.</h4>}
        </div>
        </div>:<Loading/>}
            </div>
        
        </div>
  )
}


export default ElementiOffritore